import React, {useEffect, useRef, useState} from "react";
import CarList from "../../components/car/CarList";
import SearchBar from "../../components/search/SearchBar";
import Filter from "../../components/filter/Filter";
import {sedans, suvs, trucks} from "../../test/TestData";
import axios from "../../utils/axios";
import "./ProductPage.css"
import CustomPagenation from "../../utils/CustomPagenation";
import {useSearchParams, useParams, useNavigate} from "react-router-dom";
import SearchBox from "../../components/search/SearchBox";

function ProductPage() {
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get("search") || "");
    const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
    const pageParams = searchParams.get("page");
    const [type, setType] = useState(searchParams.get("type"));
    const [category, setCategory] = useState(searchParams.get("category"));
    const categoryChanged = useRef(false);
    const navigate = useNavigate();
    const [types, setTypes] = useState({});
    const [categoryList, setCategoryList] = useState(["All"]);
    const fetchCarData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/models?page=${currentPage - 1}`, {
                params: {
                    size: 12,
                    type: type,
                    category: category,
                    search: searchQuery,
                },
            });
            setCars(response.data.content); // API 응답의 내용 중 자동차 리스트 설정
            setPage(response.data.page);
        } catch (err) {
            setError("자동차 정보를 불러오는 데 실패했습니다.");
        } finally {
            setLoading(false);
        }
    };

    const fetchMobilityClassification = async () =>{
        const response = await axios.get("/api/mobilityClassification");
        response.data.map(e => setCategoryList(prev => [...prev,e.category]))

        response.data.map(e => setTypes(prev => ({...prev,[e.category] : e.types})))
        console.log(response.data);
    }

    useEffect(() => {
        fetchMobilityClassification();
    },[])





    useEffect(() => {
        setCategory(searchParams.get("category"));
        setType(searchParams.get("type"));
        setSearchQuery(searchParams.get("search"));
    }, [searchParams])

    const handleCategoryChange = (e) => {
        const newCategory = e.target.value;
        const firstType = types[newCategory]?.[0] || "";

        if (newCategory === "All") {
            setSearchQuery("");
            setCategory("");
            setType("");
        } else {
            setSearchQuery("");
            setCategory(newCategory);
            setType(firstType);
            setSearchParams({category: newCategory, type: firstType});
        }
    };

    useEffect(() => {
        setCurrentPage(searchParams.get("page") || 1);
    }, [pageParams, type, category,searchQuery])

    useEffect(() => {
        fetchCarData();
    }, [currentPage, type, category,searchQuery]);

    useEffect(() => {
        if (categoryChanged.current) {
            const firstType = types[category]?.[0] || "";
            setType(firstType);
            categoryChanged.current = false; // 한 번 변경 후 다시 초기화
        }
        console.log(types);
    }, [category]);

    const handleActivePage = (page) => {
        setCurrentPage(page);
        const newParams = new URLSearchParams(searchParams);
        newParams.set("page", page);
        setSearchParams(newParams);
    }

    return (
        <div className="product-page">
            <div className="info-bar">
                <span>Product {category ? '/ ' + category : ""} {type ? '/ ' + type : ""}</span>
            </div>
            <div className="search-filter-menu">
                <form className="filter-form">
                    <div className="filter-group">
                        <div>
                            {/*<label htmlFor="category">카테고리</label>*/}
                            <select name="category" id="category" value={category} className="filter-select"
                                    onChange={handleCategoryChange}>
                                {
                                    categoryList.map((e) => <option value={e}>{e}</option>)
                                }
                            </select>
                        </div>

                        {
                            category != "All" ? <div>
                                {/*<label htmlFor="type">유형</label>*/}
                                <select name="type" id="type" value={type} className="filter-select"
                                        onChange={(e) => setType(e.target.value)} disabled={category === "" ? true : false}>
                                    {
                                        types[category]?.map((type) => {
                                            return <option key={type} value={type}>
                                                {type}
                                            </option>
                                        })
                                    }
                                </select>
                            </div> : ""
                        }



                        {/*<label htmlFor="fuel_type">연료 유형 :</label>*/}
                        {/*<select name="fuel_type" id="fuel_type" className="filter-select">*/}
                        {/*    <option value="gasoline">가솔린</option>*/}
                        {/*    <option value="diesel">디젤</option>*/}
                        {/*    <option value="electric">전기</option>*/}
                        {/*</select>*/}

                        {/*<label htmlFor="body">바디 타입 :</label>*/}
                        {/*<select name="body" id="body" className="filter-select">*/}
                        {/*    <option value="sedan">세단</option>*/}
                        {/*    <option value="suv">SUV</option>*/}
                        {/*    <option value="truck">트럭</option>*/}
                        {/*</select>*/}

                        {/*<button className="filter-submit">검색</button>*/}
                    </div>
                </form>
                <SearchBox></SearchBox>
            </div>
            <hr/>
            <CarList cars={cars}/>

            <div className="mt-3">
                <CustomPagenation page={page} handleActivePage={handleActivePage}/>
            </div>
        </div>
    );
}

export default ProductPage;
