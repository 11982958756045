import React from "react";
import { FaHandshake, FaDollarSign, FaCar } from "react-icons/fa";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import "./CoreFeatures.css";

export default function CoreFeatures() {
    // i18next 훅 불러오기
    const { t } = useTranslation();

    return (
        <section className="core-features">
            <div className="container">
                {/* 타이틀 */}
                <h2 className="title">{t("coreFeatures.title")}</h2>

                {/* 서브타이틀 */}
                <p className="subtitle">
                    {t("coreFeatures.subtitle")}
                </p>

                <div className="feature-grid">
                    {/* 1. 딜러와 바이어 연결 */}
                    <motion.div whileHover={{ scale: 1.05 }} className="feature-card">
                        <FaHandshake className="icon handshake" />
                        <h3>{t("coreFeatures.feature1Title")}</h3>
                        <p>{t("coreFeatures.feature1Desc")}</p>
                    </motion.div>

                    {/* 2. 합리적 가격 & 높은 품질 */}
                    <motion.div whileHover={{ scale: 1.05 }} className="feature-card">
                        <FaDollarSign className="icon price" />
                        <h3>{t("coreFeatures.feature2Title")}</h3>
                        <p>{t("coreFeatures.feature2Desc")}</p>
                    </motion.div>

                    {/* 3. 신뢰할 수 있는 거래 시스템 */}
                    <motion.div whileHover={{ scale: 1.05 }} className="feature-card">
                        <FaCar className="icon car" />
                        <h3>{t("coreFeatures.feature3Title")}</h3>
                        <p>{t("coreFeatures.feature3Desc")}</p>
                    </motion.div>
                </div>
            </div>
        </section>
    );
}
