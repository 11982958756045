export const CONTACT_INFO = {
    EMAIL: {
        JJMOTORS: "jjmotorsinfo@jjmotors.net",
        CHINA: "jj_zhangxiaoyu9@qq.com"
    },
    PHONE: {
        KOREA: "+82 (10)-4169-6887",
        CHINA: "+86 15666796275"
    }
}

export const COUNTRY_COUNT = 177

export const BUYER_COUNT = 5000
export const MOBILITY_CATEGORY = [
    "ICE Car",
    "Electric Car",
    "Electric Bike",
    "Excavator",
    "Truck",
    "Van",
    "MPV",
    "Pickup"
]

export const MOBILITY_TYPE = {
    "ICE Car": [
        "Compact Sedan",
        "Compact SUV",
        "Mid-size SUV",
    ],
    "Electric Car": [
        "Mini Car",
        "Compact Sedan",
        "Mid-Size Sedan",
        "Mid-to-Large-Sized Sedan",
        "Full-Size Sedan",
        "Compact SUV",
        "Mid-size SUV",
        "Mid-to-Large-Sized SUV",
        "Full-Size SUV"
    ],
    "Electric Bike": [
        "E-Bike",
        "Throttle E-Bike",
        "E-Scooter"
    ],
    "Excavator": [
        "Mini Excavator",
        "Medium Excavator",
    ],
    "Truck": [
        "Light Cargo truck",
        "Heavy Cargo Truck",
        "Concrete Mixer Truck",
        "Dump Truck",
        "Tanker Truck",
        "Tractor Head",
        "Mini Tipper Truck",
        "LNG Truck",
        "CNG Truck",
        "Tractor Head",
    ],
    "Van": ["Van"],
    "MPV": ["MPV"],
    "Pickup": ["Pickup"],
    "BUS":[
        "EV bus",
        "fuel bus"
    ]

}