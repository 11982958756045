export const formatDate = (dateString) => {
    const postDate = new Date(dateString);
    const today = new Date();
    const isToday = postDate.toDateString() === today.toDateString();

    return postDate.toLocaleDateString("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    });
    // if (isToday) {
    //     return postDate.toLocaleTimeString("ko-KR", {
    //         hour: "2-digit",
    //         minute: "2-digit",
    //         hour12: false,
    //     });
    // } else {
    //     return postDate.toLocaleDateString("ko-KR", {
    //         year: "numeric",
    //         month: "2-digit",
    //         day: "2-digit",
    //     });
    // }
};
