import React, {useEffect, useState, useRef} from "react";
import axios from "../../utils/axios";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {FaThumbsUp} from "react-icons/fa";
import styles from "./PostPage.module.css";
import {getDecodedToken} from "../../utils/jwtUtils";
import {useTranslation} from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import {formatDate} from "../../utils/formatDate";
import CustomPagenation from "../../utils/CustomPagenation"
import {useDispatch, useSelector} from "react-redux";
import {handleShowLogin} from "../../store/store";
import {countries} from "../../components/user/countryData";
import PostList from "../../components/post/PostList";

const PostPage = () => {
    const {t} = useTranslation();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
    const isLoggedIn = useSelector((state) => state.auth);
    const [searchType, setSearchType] = useState("title");
    const [page, setPage] = useState({});
    const [postListSize, setPostListSize] = useState(10);
    const searchKeywordRef = useRef("");
    const navigate = useNavigate();
    const pageParam = parseInt(searchParams.get("page") || '1', 10);
    const [categories, setCategories] = useState([
        "All",
        "FreeBoard",
        "PartsReview",
        "TestDriveReview",
        "QnABoard",
        "InformationBoard",
        "UsedCarMarketplace",
        "MechanicAdvice"
        // "CarNews",
        // "EventsMeetups",
        // "CommunityTipsRecommendations",
    ]);
    const [selectedCategory, setSelectedCategory] = useState(searchParams.get("category") || "All");
    let dispatch = useDispatch();
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getDecodedToken();

    const threeToTwoMap = countries.reduce((acc, {value, flag}) => {
        acc[value] = flag;
        return acc;
    }, {});

    function getTwoLetterCode(threeLetterCode) {
        // 매핑 객체에서 코드가 존재하지 않으면 빈 문자열("")을 반환
        return threeToTwoMap[threeLetterCode] || "";
    }

    const handleWrite = () => {
        console.log(isLoggedIn);
        if (isLoggedIn) {
            navigate("/post/new");
        } else {
            dispatch(handleShowLogin());
        }
    }
    const fetchPosts = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/post`, {
                params: {
                    page: currentPage - 1, // 1 기반에서 0 기반으로 변환
                    size: postListSize,
                    category: selectedCategory,
                    searchType: searchType,
                    keyword: searchKeywordRef.current,
                    sort: 'id,desc',
                },
            });
            setPosts(response.data.content);
            setPage(response.data.page);
        } catch (error) {
            console.error("Error fetching posts:", error);
            setPosts([]);
            setPage({number: 0, totalPages: 1});
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     fetchPosts();
    // });
    // useEffect(() => {
    //     fetchPosts();
    // }, []);

    useEffect(() => {
        setCurrentPage(pageParam);
        setSelectedCategory(searchParams.get("category") || 'All')
        fetchPosts();
        // setSearchParams({page : currentPage});
    }, [currentPage, selectedCategory, searchType]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const newParams = new URLSearchParams(searchParams);
        newParams.set("page", pageNumber);
        setSearchParams(newParams);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setSearchParams({page: 1, category: category});
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setCurrentPage(0);
    };

    const handleInputChange = (event) => {
        searchKeywordRef.current = event.target.value;
    };

    // 국가 코드로 국기 이미지를 가져오는 함수
    const getFlagUrl = (countryCode) => {
        return `https://flagcdn.com/16x12/${countryCode.toLowerCase()}.png`;
    };

    return (
        <div className={styles.contentContainer}>
            {/* 카테고리 리스트 */}
            <div className={styles.categoryList}>
                <span>{t('postList.header.category')}  </span>
                <select name="category" id="category" onChange={(e) => handleCategoryChange(e.target.value)}
                        value={selectedCategory}>
                    {categories.map((category) => (
                        <option
                            key={category}
                            className={`${styles.categoryButton} ${selectedCategory === category ? styles.activeCategory : ""}`}
                            value={category}
                        >
                            {t(`postList.categories.${category}`)}
                        </option>
                    ))}
                </select>

            </div>
            {/* 검색바 */}
            <div className={styles.postMenu}>
                <div className={styles.searchContainer}>
                    {/*<select*/}
                    {/*    value={searchType}*/}
                    {/*    onChange={(e) => setSearchType(e.target.value)}*/}
                    {/*>*/}
                    {/*    <option value="title">{t('postList.search.title')}</option>*/}
                    {/*    <option value="author">{t('postList.search.author')}</option>*/}
                    {/*    <option value="content">{t('postList.search.content')}</option>*/}
                    {/*</select>*/}
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    defaultValue={searchKeywordRef.current}*/}
                    {/*    onChange={handleInputChange}*/}
                    {/*    placeholder={t('postList.search.placeholder')}*/}
                    {/*/>*/}
                    {/*<button onClick={handleSearch}><i className="bi bi-search"></i></button>*/}
                    {/* 글쓰기 버튼 (로그인된 경우에만 보임) */}
                    {/*{isLoggedIn && (*/}

                    {/*)}*/}
                </div>
                {/*() => dispatch(handleShowLogin())*/}
                <i className={`bi bi-pencil-square ${styles.writeButton}`} onClick={() => handleWrite()}></i>
            </div>


            {/* 게시글 목록 헤더 */}
            <div className={styles.postListHeader}>
                <div className={styles.postInfoContainer}>
                    <div className={styles.postInfoLeft}>
                        <span className={styles.postCategory}>{t('postList.header.category')}</span>
                        <span className={styles.postTitle}>{t('postList.header.title')}</span>
                    </div>
                    <div className={styles.postInfoRight}>
                        <span className={styles.postAuthor}>{t('postList.header.author')}</span>
                        <span className={styles.postCountry}>{t('postList.header.country')}</span>
                        <span className={styles.viewCount}>{t('postList.header.views')}</span>
                        <span className={styles.likeCount}>{t('postList.header.likes')}</span>
                        <span className={styles.postDateHeader}>{t('postList.header.date')}</span>
                    </div>
                </div>
            </div>

            {/* 게시글 리스트 */}
            <PostList posts={posts} page={page.number}/>
            <CustomPagenation page={page} handleActivePage={handlePageChange}/>
        </div>
    );
};

export default PostPage;
