import React, {useState} from "react";
import Navbar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";
import Approutes from "./routes/AppRoutes";
import {useSelector, useDispatch} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import {
    store,
    persistor,
    login,
    logout,
    handleShowLogin,
    handleShowSignup,
    handleShowForgotPassword,
    handleBack
} from "./store/store";
import {useEffect} from 'react';
import "./App.css";
import "./i18n"; // i18n 초기화 파일 가져오기
import {checkTokenExpiration} from './utils/CheckTokenExpiration'; // 토큰 만료 유틸리티 함수 임포트
import LoginModal from "./components/user/LoginModal";
import SignupModal from "./components/user/SignupModal";
import ForgotPasswordModal from "./components/user/ForgotPasswordModal";
import axios from "./utils/axios";


function App() {

    const dispatch = useDispatch();

    const fetchLoginStatus = async () => {
        const response = await axios.get(`/auth/status`,{withCredentials: true});
        if (response.data) {
            dispatch(login());
        } else {
            dispatch(logout());
        }
    }

    useEffect( () => {
        fetchLoginStatus();
    }, [dispatch]);

    useEffect(() => {
        // 앱 로드 시 토큰 만료 체크
        const token = localStorage.getItem('token');
        if (token) {
            checkTokenExpiration(token, dispatch);
        }
    }, [dispatch]);


    const showLogin = useSelector((state) => state.authModal.showLogin);
    const showSignup = useSelector((state) => state.authModal.showSignup);
    const showForgotPassword = useSelector((state) => state.authModal.showForgotPassword);


    return (

        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>

                <div className="App">
                    {/* 로그인 모달 */}
                    <LoginModal
                        show={showLogin}  // showLogin 상태로 모달을 열고 닫음
                        handleClose={() => dispatch(handleShowLogin())}
                        handleSignupShow={() => dispatch(handleShowSignup())}
                        handleForgotPasswordShow={() => dispatch(handleShowForgotPassword())}
                    />
                    {/* 회원가입 모달 */}
                    <SignupModal
                        show={showSignup}
                        handleClose={() => dispatch(handleShowSignup())}
                        handleBack={() => dispatch(handleBack())}
                    />
                    {/* 비밀번호 찾기 모달 */}
                    <ForgotPasswordModal
                        show={showForgotPassword}
                        handleClose={() => dispatch(handleShowForgotPassword())}
                        handleBack={() => dispatch(handleBack())}
                    />

                    <Navbar/>

                    <div id="container">
                        <div className="ad-container">
                            <div className="ad-content">
                                ADVERTISEMENT
                            </div>
                        </div>
                        <div className="content-container">
                            <Approutes/>
                        </div>
                        <div className="ad-container">
                            <div className="ad-content">
                                ADVERTISEMENT
                            </div>
                        </div>
                    </div>

                </div>
                <Footer/>
            </PersistGate>
        </Provider>


    );
}

export default App;
