import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import CarItem from "./CarItem";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const CarList = ({ title, cars, category,listSize }) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
      <div>
          <div>
              <h2>{title}</h2>
          </div>
          {cars.length === 0 ? (
              <p>{t("noData")}</p>
          ) : (
              <Row className="g-3 "> {/* 간격 유지 */}
                  {cars?.slice(0, listSize).map((car, index) => (
                      <Col key={index} xs={6} sm={6} md={5} lg={4} xl={3} >
                          <CarItem car={car} />
                      </Col>
                  )) || ""}
              </Row>
          )}
      </div>
  );
};

export default CarList;
