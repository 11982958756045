import React from 'react';
import ContactForm from "../../components/contact/ContactForm";
import "./ContactPage.css"
import Flag from "react-world-flags";
import {CONTACT_INFO} from "../../constants";
const ContactPage = () => {
    return (
        <div className="container contact-page">
            <div className="contact-info">
                <h3>Contact Info</h3>
                <div className="contact-card">
                    <div className="contact-item">
                        <span className="contact-label">Email:</span>
                        <span className="contact-value">{CONTACT_INFO.EMAIL.JJMOTORS}</span>
                    </div>
                    <div className="contact-item">
                        <span className="contact-label">Email:</span>
                        <span className="contact-value">{CONTACT_INFO.EMAIL.CHINA}</span>
                    </div>
                    <div className="contact-item">
                        <Flag code="cn" size="32" />
                        <span className="contact-label">Phone:</span>
                        <span className="contact-value">{CONTACT_INFO.PHONE.CHINA}</span>
                    </div>
                    <div className="contact-item">
                        <Flag code="kr" size="32" />
                        <span className="contact-label">Phone:</span>
                        <span className="contact-value">{CONTACT_INFO.PHONE.KOREA}</span>
                    </div>
                </div>
            </div>
            <ContactForm></ContactForm>
        </div>
    );
};

export default ContactPage;