import React from "react";
import {Routes, Route, Outlet} from "react-router-dom";
import MainPage from "../pages/home/MainPage";
import NotFound404 from "../pages/404/NotFound404";
import ProductPage from "../pages/product/ProductPage";
import QnA from "../pages/qna/QnA";
import MobilityModelUpload from "../components/model/MobilityModelUpload";
import MobilitySeriesUpload from "../pages/MobilitySeriesUpload";
import AdminDashboard from "../pages/admin/AdminDashboard";
import AdminLayout from "../pages/admin/Layout/AdminLayout";
import MobilityModelDetail from "../components/model/MobilityModelDetail";
import ManufacturerList from "../components/manufacturer/ManufacturerList";
import AddManufacturer from "../components/manufacturer/AddManufacturer";
import BrandList from "../components/brand/BrandList";
import AddBrand from "../components/brand/AddBrand";
import SeriesList from "../components/series/SeriesList";
import AddSeries from "../components/series/AddSeries";
import MobilityModelList from "../components/model/MobilityModelList";
import BuyerListPage from "../components/buyer/BuyerListPage";
import RoleProtectedRoute from "./RoleProtectedRoute"; // 역할 기반 보호 라우트 임포트
import MyProfile from "../components/user/MyProfile";
import ContactList from "../components/contact/ContactList"
import GlobalPartners from "../pages/global-partner/GlobalPartners";
import AboutJJmotors from "../pages/about/AboutJJmotors"
import Gallery from "../pages/about/Gallery"
import BannerManagement from "../pages/admin/banner/BannerManagement";
import ScrollToTop from "../utils/ScrollToTop";
import ContactPage from "../pages/contact/ContactPage";
import PostPage from "../pages/post/PostPage";
import PostDetail from "../pages/post/PostDetail";
import PostCreate from "../components/post/PostCreate";

const AppRoutes = () => {

    const Roles = {
        ADMIN: 'ROLE_ADMIN',
        USER: 'ROLE_USER',
        GUEST: 'ROLE_GUEST',
    };

    return (
        <>
            <ScrollToTop></ScrollToTop>
            <Routes>
                <Route path="/" element={<MainPage/>}/>
                <Route path="/sale" element={<div>판매</div>}/>
                <Route path="/product" element={<ProductPage/>}/>
                <Route path="/qna" element={<QnA/>}/>
                <Route path="/contact" element={<ContactPage/>}/>
                <Route path="/mobilityModel/:id" element={<MobilityModelDetail/>}/>
                <Route path="/mobilityModelUpload" element={<MobilityModelUpload/>}/>
                <Route path="/mobilitySeriesUpload" element={<MobilitySeriesUpload/>}/>
                <Route path="/post" element={<PostPage/>}/>
                <Route path="/post/:id" element={<PostDetail/>}/>
                <Route path="/globalPartners" element={<GlobalPartners/>}/>
                <Route path="/about" element={<AboutJJmotors/>}/>
                <Route path="/gallery" element={<Gallery/>}/>


                <Route
                    path="/post"
                    element={<RoleProtectedRoute element={<><Outlet/></>} requiredRole={[Roles.USER,Roles.ADMIN]}/>}
                >
                    <Route path="new" element={<PostCreate/>}/>
                </Route>
                <Route
                    path="/user"
                    element={<RoleProtectedRoute element={<><Outlet/></>} requiredRole={[Roles.USER,Roles.ADMIN]}/>}
                >
                    <Route path="profile" element={<MyProfile/>}/>
                </Route>

                {/* 관리자 권한이 필요한 라우트 */}
                <Route
                    path="/admin"
                    element={<RoleProtectedRoute element={<AdminLayout/>} requiredRole={[Roles.ADMIN]}/>}
                >
                    {/* 관리자 전용 하위 라우트 */}
                    <Route path="dashboard" element={<AdminDashboard/>}/>
                    <Route path="manufacturer" element={<ManufacturerList/>}/>
                    <Route path="manufacturer/add" element={<AddManufacturer/>}/>
                    <Route path="brand" element={<BrandList/>}/>
                    <Route path="brand/add" element={<AddBrand/>}/>
                    <Route path="series" element={<SeriesList/>}/>
                    <Route path="series/add" element={<AddSeries/>}/>
                    <Route path="model" element={<MobilityModelList/>}/>
                    <Route path="model/add" element={<MobilityModelUpload/>}/>
                    <Route path="model/edit/:id" element={<MobilityModelUpload/>}/>
                    <Route path="buyer" element={<BuyerListPage/>}/>
                    <Route path="contact" element={<ContactList/>}/>
                    <Route path="banner" element={<BannerManagement/>}/>

                </Route>
                {/* 404 페이지 */}
                <Route path="*" element={<NotFound404/>}/>
            </Routes>
        </>
    );
};

export default AppRoutes;
