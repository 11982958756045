import React, {useState, useCallback, useEffect} from "react";
import {
    Tabs,
    Tab,
    Form,
    Button,
    Container,
    Row,
    Col,
    Alert,
    Image
} from "react-bootstrap";
import {useDropzone} from "react-dropzone";
// import "bootstrap/dist/css/bootstrap.min.css";
import {useNavigate, useParams} from "react-router-dom";
import axios from "../../utils/axios";
import {useTranslation} from "react-i18next"; // i18n 추가
import styles from "./MobilityModelUpload.module.css";
import {
    FaAlignCenter,
    FaAlignLeft, FaAlignRight,
    FaBold, FaImage,
    FaItalic, FaLink,
    FaListOl,
    FaListUl,
    FaStrikethrough,
    FaUnderline, FaYoutube
} from "react-icons/fa";
import {EditorContent, useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Youtube from "@tiptap/extension-youtube";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align"; // 모듈 CSS 가져오기
import {Image as TipTapImage} from "@tiptap/extension-image";
import DOMPurify from "dompurify";
import {forEach} from "react-bootstrap/ElementChildren";
import {Typeahead} from "react-bootstrap-typeahead";

const CarUploadPage = () => {
    const {t} = useTranslation(); // i18n 훅 사용
    const {id} = useParams();
    const [seriesOptions, setSeriesOptions] = useState([]);
    const [images, setImages] = useState([]);
    const [thumbnailIndex, setThumbnailIndex] = useState(0);
    const [carInfo, setCarInfo] = useState({});
    const [additionalTabs, setAdditionalTabs] = useState([]);
    const [activeKey, setActiveKey] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [deletedImages, setDeletedImages] = useState([]);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchCarData = async (carId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/models/${id}`);
            const carData = response.data;

            const newAdditionalTabs = [];

            carData.parameters.forEach((param) => {
                let existingTab = newAdditionalTabs.find(
                    (tab) => tab.title === param.category
                );

                if (existingTab) {
                    existingTab.data[param.parameterName] = param.parameterValue;
                    existingTab.fieldNames[param.parameterName] = param.parameterName;
                } else {
                    newAdditionalTabs.push({
                        key: param.category,
                        title: param.category,
                        data: {[param.parameterName]: param.parameterValue},
                        fieldNames: {[param.parameterName]: param.parameterName},
                    });
                }
            });

            setAdditionalTabs(newAdditionalTabs);

            setCarInfo({
                modelName: carData.modelName || "",
                price: carData.price.toString() || "",
                year: carData.year || "",
                stockStatus: carData.stockStatus || t("carUpload.stockStatusOptions.inStock"),
                seriesId: carData.series.id || "",
            });


            setImages(
                carData.images.map((image, index) => ({
                    id: image.id,
                    imageUrl: image.imageUrl,
                    imageStatus: "UNCHANGED",
                    isExisting: true,
                }))
            );

            carData.images.forEach((e,i,a) => {
                if(e.isThumbnail){
                    setThumbnailIndex(i);
                }
            })

            setActiveKey(carData.parameters[0].category);

            editor.commands.setContent(carData.description);
        } catch (error) {
            console.error("Error fetching car data:", error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchCarData(id);
        }
    }, [id]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/api/series`)
            .then((response) => {
                const data = response.data;
                console.log(data);
                if (Array.isArray(data.content)) {
                    setSeriesOptions(data.content.sort((a,b) => a.seriesName.localeCompare(b.seriesName)));
                } else {
                    setSeriesOptions([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching series data:", error);
                setSeriesOptions([]);
            });
    }, []);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const imagePreviews = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    fileName: file.name,
                })
            );

            setImages((prevImages) => [...prevImages, ...imagePreviews]);
        },
        [setImages]
    );

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "image/gif": [],
            "image/bmp": [],
            "image/webp": [],
        },
        multiple: true,
        onDropRejected: () => {
            alert(t("carUpload.imageUploadError"));
        },
    });

    const handleThumbnailSelect = (index) => {
        console.log(index);
        setThumbnailIndex(index);
    };

    const handleDeleteImage = (image, index) => {
        setImages((prevImages) =>
            prevImages.map((img, i) =>
                i === index ? {...img, imageStatus: "DELETED"} : img
            )
        );
        setDeletedImages((prevDeleted) => [...prevDeleted, image.url]);

        if (thumbnailIndex === index) {
            setThumbnailIndex(0);
        }
    };

    const handleRestoreImage = (index) => {

        setImages((prevImages) =>
            prevImages.map((img, i) =>
                i === index ? {...img, imageStatus: "UNCHANGED"} : img
            )
        );

        setDeletedImages((prevDeleted) =>
            prevDeleted.filter((url) => url !== images[index].url)
        );
    };

    const handleInputChange = (e, stateSetter) => {
        const {name, value} = e.target;
        stateSetter((prev) => ({...prev, [name]: value}));
    };

    const handleAdditionalTabInputChange = (e, tabKey) => {
        const {name, value} = e.target;
        setAdditionalTabs((prevTabs) =>
            prevTabs.map((tab) =>
                tab.key === tabKey
                    ? {
                        ...tab,
                        data: {...tab.data, [name]: value},
                    }
                    : tab
            )
        );
    };

    const handleStockStatusChange = (e) => {
        const {value} = e.target;
        setCarInfo((prev) => ({...prev, stockStatus: value}));
    };

    const handleTabTitleChange = (e, tabKey) => {
        const {value} = e.target;
        setAdditionalTabs((prevTabs) =>
            prevTabs.map((tab) =>
                tab.key === tabKey ? {...tab, title: value} : tab
            )
        );
    };

    const handleFieldNameChange = (e, tabKey, fieldKey) => {
        const {value} = e.target;
        setAdditionalTabs((prevTabs) =>
            prevTabs.map((tab) =>
                tab.key === tabKey
                    ? {
                        ...tab,
                        fieldNames: {
                            ...tab.fieldNames,
                            [fieldKey]: value,
                        },
                    }
                    : tab
            )
        );
    };

    const handleAddField = (tabKey) => {
        setAdditionalTabs((prevTabs) =>
            prevTabs.map((tab) => {
                if (tab.key === tabKey) {
                    const newFieldKey = `customField${
                        Object.keys(tab.fieldNames).length + 1
                    }`;
                    return {
                        ...tab,
                        fieldNames: {
                            ...tab.fieldNames,
                            [newFieldKey]: `New Field ${
                                Object.keys(tab.fieldNames).length + 1
                            }`,
                        },
                        data: {
                            ...tab.data,
                            [newFieldKey]: "",
                        },
                    };
                }
                return tab;
            })
        );
    };

    const handleDeleteField = (tabKey, fieldKey) => {
        setAdditionalTabs((prevTabs) =>
            prevTabs.map((tab) => {
                if (tab.key === tabKey) {
                    const newFieldNames = {...tab.fieldNames};
                    const newData = {...tab.data};
                    delete newFieldNames[fieldKey];
                    delete newData[fieldKey];
                    return {
                        ...tab,
                        fieldNames: newFieldNames,
                        data: newData,
                    };
                }
                return tab;
            })
        );
    };

    const checkForDuplicateFields = () => {
        const tabNames = new Set();

        for (let tab of additionalTabs) {
            if (tabNames.has(tab.title)) {
                setErrorMessage(t("carUpload.errorMessages.duplicateTabName"));
                return false;
            }
            tabNames.add(tab.title);

            const fieldNames = new Set();
            for (let fieldName of Object.values(tab.fieldNames)) {
                if (fieldNames.has(fieldName)) {
                    setErrorMessage(t("carUpload.errorMessages.duplicateFieldName"));
                    return false;
                }
                fieldNames.add(fieldName);
            }
        }
        setErrorMessage(null);
        return true;
    };

    const resetForm = () => {
        setImages([]);
        setThumbnailIndex(0);
        setCarInfo({
            modelName: "",
            price: "",
            year: "",
            stockStatus: t("carUpload.stockStatusOptions.inStock"),
        });
        setAdditionalTabs([]);
        setActiveKey("basic");
    };
    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                paragraph: {
                    HTMLAttributes: {
                        style: 'text-align: left'
                    }
                }
            }),
            TipTapImage,
            Youtube.configure({
                width: 640,
                height: 480,
                allowFullscreen: true,
            }),
            Link.configure({
                openOnClick: false,
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
        ],
        content: '',
    });
    const handleImageUpload = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    editor.chain().focus().setImage({src: reader.result}).run();
                };
                reader.readAsDataURL(file);
            }
        };
    };

    const handleYoutubeEmbed = () => {
        const url = prompt("Enter the YouTube URL");
        if (url) {
            editor.commands.setYoutubeVideo({src: url});
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!checkForDuplicateFields()) {
            return;
        }

        const filterEmptyFields = (categoryName, data) =>
            Object.entries(data)
                .filter(([_, value]) => value.trim() !== "")
                .map(([key, value]) => ({
                    category: categoryName,
                    parameterName: key,
                    parameterValue: value,
                }));

        const parameters = [
            ...additionalTabs.flatMap((tab) =>
                Object.entries(tab.fieldNames).map(([key, fieldName]) => ({
                    category: tab.title,
                    parameterName: fieldName,
                    parameterValue: tab.data[key]?.trim() || "",
                }))
            ),
        ];

        const formData = new FormData();

        // JSON 데이터 추가
        const carData = {
            modelName: carInfo.modelName,
            price: carInfo.price,
            year: carInfo.year,
            seriesId: carInfo.seriesId,
            parameters: parameters,
            thumbnailIndex: thumbnailIndex,
            stockStatus: carInfo.stockStatus,
            description: DOMPurify.sanitize(editor.getHTML(), {
                ALLOWED_TAGS: ["p", "b", "i", "u", "a", "img", "ul", "ol", "li", "br"],
                ALLOWED_ATTR: ["href", "src", "alt", "title", "style"],
            }),
        };

        formData.append(
            "carData",
            new Blob([JSON.stringify(carData)], { type: "application/json" })
        );

        let imageDtos = [];
        // 파일 데이터 추가
        [...images].forEach((image, index) => {
            if (image instanceof File) {
                formData.append("imageFiles", image, image.name);
            }else{
                imageDtos = [...imageDtos, image];
            }
        });

        formData.append("images",new Blob([JSON.stringify(imageDtos)], { type: "application/json" }));

        const requestConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        if (id) {
            axios
                .put(`${apiUrl}/api/models/${id}`, formData, requestConfig)
                .then((response) => {
                    alert(t("carUpload.successUpdate"));
                    resetForm();
                    navigate("/admin/model");
                })
                .catch((error) => {
                    console.error("There was an error uploading the data:", error);
                });
        } else {
            axios
                .post(`${apiUrl}/api/models`, formData, requestConfig)
                .then((response) => {
                    alert(t("carUpload.successUpload"));
                    resetForm();
                        navigate("/admin/model");
                })
                .catch((error) => {
                    console.error("There was an error uploading the data:", error);
                });
        }
    };

    const handleAddTab = () => {
        const newKey = `customTab${additionalTabs.length + 1}`;
        const newTab = {
            key: newKey,
            title: t("carUpload.customTabTitle", {count: additionalTabs.length + 1}),
            data: {},
            fieldNames: {
                customField1: t("carUpload.customField", {count: 1}),
            },
        };
        setAdditionalTabs([...additionalTabs, newTab]);
        setActiveKey(newKey);
    };

    const handleDeleteTab = (tabKey) => {
        setAdditionalTabs((prevTabs) => prevTabs.filter((tab) => tab.key !== tabKey));
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.target.blur();
        }
    };

    const handleSeriesChange = (selected) => {
        // 사용자가 선택한 옵션이 있으면 seriesId를 업데이트하고, 없으면 빈 값으로 세팅
        if (selected && selected.length > 0) {
            setCarInfo((prev) => ({
                ...prev,
                seriesId: selected[0].id,
            }));
        } else {
            setCarInfo((prev) => ({
                ...prev,
                seriesId: '',
            }));
        }
    };

    return (
        <div className={`${styles.containerStyle} mt-5 p-4 w-75 m-auto`}>
            <h1 className={`mb-4 ${styles.tabTitle}`}>{t("carUpload.pageTitle")}</h1>

            {successMessage && (
                <Alert
                    variant="success"
                    onClose={() => setSuccessMessage("")}
                    dismissible
                >
                    {successMessage}
                </Alert>
            )}

            {errorMessage && (
                <Alert
                    variant="danger"
                    onClose={() => setErrorMessage(null)}
                    dismissible
                >
                    {errorMessage}
                </Alert>
            )}

            <Form.Group className="mb-4">
                <Form.Label className={styles.formLabel}>
                    {t("carUpload.seriesSelectLabel")}
                </Form.Label>
                <Typeahead
                    id="series-select"
                    // 표시할 텍스트 설정 (labelKey)
                    labelKey={(series) =>
                        `${series.seriesName} - ${series.brandName} / ${series.mobilityCategory} - ${series.mobilityType}`
                    }
                    onChange={handleSeriesChange}
                    // 검색 대상 리스트
                    options={seriesOptions || []}
                    // 이미 선택된 값 세팅
                    selected={
                        seriesOptions?.filter((series) => series.id === carInfo.seriesId) || []
                    }
                    // placeholder 설정
                    placeholder={t("carUpload.seriesSelectPlaceholder")}
                    // true면 검색 시 필터링 + 선택값 지울 수 있는 x버튼 표시
                    clearButton
                    className={styles.formControl}
                />
            </Form.Group>

            <h2 className={styles.commonInfoTitle}>{t("carUpload.commonInfoTitle")}</h2>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label className={styles.formLabel}>
                            {t("carUpload.modelName")}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="modelName"
                            value={carInfo.modelName}
                            onChange={(e) => handleInputChange(e, setCarInfo)}
                            className={styles.formControl}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label className={styles.formLabel}>
                            {t("carUpload.price")}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="price"
                            value={carInfo.price}
                            onChange={(e) => handleInputChange(e, setCarInfo)}
                            className={styles.formControl}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label className={styles.formLabel}>
                            {t("carUpload.year")}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="year"
                            value={carInfo.year}
                            onChange={(e) => handleInputChange(e, setCarInfo)}
                            className={styles.formControl}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <div className="toolbar">
                <Button title="Bold" onClick={() => editor.chain().focus().toggleBold().run()}
                        className="toolbarButton">
                    <FaBold/>
                </Button>
                <Button title="Italic" onClick={() => editor.chain().focus().toggleItalic().run()}
                        className="toolbarButton">
                    <FaItalic/>
                </Button>
                <Button title="Underline" onClick={() => editor.chain().focus().toggleUnderline().run()}
                        className="toolbarButton">
                    <FaUnderline/>
                </Button>
                <Button title="Strikethrough" onClick={() => editor.chain().focus().toggleStrike().run()}
                        className="toolbarButton">
                    <FaStrikethrough/>
                </Button>
                <Button title="Bullet List" onClick={() => editor.chain().focus().toggleBulletList().run()}
                        className="toolbarButton">
                    <FaListUl/>
                </Button>
                <Button title="Ordered List" onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        className="toolbarButton">
                    <FaListOl/>
                </Button>
                <Button title="Align Left" onClick={() => editor.chain().focus().setTextAlign('left').run()}
                        className="toolbarButton">
                    <FaAlignLeft/>
                </Button>
                <Button title="Align Center" onClick={() => editor.chain().focus().setTextAlign('center').run()}
                        className="toolbarButton">
                    <FaAlignCenter/>
                </Button>
                <Button title="Align Right" onClick={() => editor.chain().focus().setTextAlign('right').run()}
                        className="toolbarButton">
                    <FaAlignRight/>
                </Button>
                <Button title="Upload Image" onClick={handleImageUpload} className="toolbarButton">
                    <FaImage/>
                </Button>
                <Button title="Embed YouTube Video" onClick={handleYoutubeEmbed} className="toolbarButton">
                    <FaYoutube/>
                </Button>
                <Button title="Insert Link" onClick={() => editor.chain().focus().toggleLink().run()}
                        className="toolbarButton">
                    <FaLink/>
                </Button>
            </div>

            <div className="editorContainer" onClick={() => editor.chain().focus().run()}>
                <EditorContent editor={editor}/>
            </div>

            <div {...getRootProps()} className={styles.imageUploadSection}>
                <input {...getInputProps()} />
                <p>{t("carUpload.imageUploadText")}</p>
            </div>

            <Container className="d-flex flex-wrap">
                {images.map((image, index) => (
                    <div
                        key={index}
                        style={{position: "relative", margin: "10px", width: "150px"}}
                    >
                        <Image
                            src={image.isExisting ? image.imageUrl : image.preview}
                            className={index === thumbnailIndex ? styles.imageThumbnail : styles.imagePreview}
                        />
                        {/*{image.isDeleted && (*/}
                        {/*    <div className={styles.imageOverlay}>X</div>*/}
                        {/*)}*/}
                        {image.isDeleted ? (
                            <Button
                                variant="success"
                                size="sm"
                                onClick={() => handleRestoreImage(index)}
                            >
                                {t("carUpload.restoreImage")}
                            </Button>
                        ) : (
                            <div className="d-flex flex-row">
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleThumbnailSelect(index)}
                                >
                                    <i className="bi bi-card-image"></i>
                                    {/*{t("carUpload.selectThumbnail")}*/}
                                </Button>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => handleDeleteImage(image, index)}
                                >
                                    <i className="bi bi-trash3-fill"></i>
                                    {/*{t("carUpload.deleteImage")}*/}
                                </Button>
                            </div>
                        )}
                    </div>
                ))}
            </Container>

            <Form onSubmit={handleSubmit} id={styles.parameterForm}>
                <Tabs
                    activeKey={activeKey}
                    onSelect={(k) => k !== "add" && setActiveKey(k)}
                    className="mb-3"
                >
                    {additionalTabs.map((tab) => (
                        <Tab
                            key={tab.key}
                            eventKey={tab.key}
                            title={
                                <div style={{display: "flex", alignItems: "center", height: "100%", width: "100%"}}>
                                    <Form.Control
                                        type="text"
                                        value={tab.title}
                                        onChange={(e) => handleTabTitleChange(e, tab.key)}
                                        onKeyPress={handleKeyPress}
                                        style={{
                                            backgroundColor: "#e9ecef",
                                            border: "none",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            height: "100%",
                                            width: "100%",
                                            margin: "0",
                                            borderRadius: "0"
                                        }}
                                    />
                                    <Button
                                        size="sm"
                                        onClick={() => handleDeleteTab(tab.key)}
                                        style={{height: "100%", width: "24px", borderRadius: "0"}}
                                    >
                                        X
                                    </Button>
                                </div>
                            }
                        >
                            {Object.entries(tab.fieldNames).map(([fieldKey, fieldLabel]) => (
                                <Form.Group className="mb-3" key={fieldKey}>
                                    <Row className="align-items-center">
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                value={fieldLabel}
                                                onChange={(e) => handleFieldNameChange(e, tab.key, fieldKey)}
                                                onKeyPress={handleKeyPress}
                                                className={styles.formControl}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                name={fieldKey}
                                                value={tab.data[fieldKey] || ""}
                                                onChange={(e) => handleAdditionalTabInputChange(e, tab.key)}
                                                className={styles.formControl}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                className={styles.deleteFieldButton}
                                                onClick={() => handleDeleteField(tab.key, fieldKey)}
                                            >
                                                {t("carUpload.deleteField")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            ))}
                            <Button
                                className={styles.addFieldButton}
                                onClick={() => handleAddField(tab.key)}
                            >
                                {t("carUpload.addField")}
                            </Button>
                        </Tab>
                    ))}

                    <Tab
                        eventKey="add"
                        title={
                            <div className={styles.addTabButton} onClick={handleAddTab}>
                                +
                            </div>
                        }
                        tabClassName="add-tab"
                        className={styles.addTab}
                        style={{padding: "0"}}

                    />
                </Tabs>

                <Button variant="primary" type="submit" style={{marginTop: "20px", width: "140px"}}>
                    {id ? t("carUpload.updateButton") : t("carUpload.uploadButton")}
                </Button>
            </Form>
        </div>
    );
};

export default CarUploadPage;
