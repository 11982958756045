import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "../../utils/axios";
import Carousel from "react-bootstrap/Carousel";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {useTranslation} from 'react-i18next';
import styles from "./MobilityModelDetail.module.css";
import Inquire from "../Inquire/Inquire";
import CarList from "../car/CarList";

const MobilityModelDetail = () => {
    const {id} = useParams();
    const [carData, setCarData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tabKey, setTabKey] = useState("specification");
    const [activeIndex, setActiveIndex] = useState(0);
    const {t} = useTranslation();
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [similarCars, setSimilarCars] = useState([]);

    const fetchCarData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/models/${id}`);
            setCarData(response.data);
        } catch (error) {
            console.error("Error fetching car data:", error);
        } finally {
            setLoading(false);
        }
    };

    const getSimilarCars = async () => {
        const response = await axios.get(`api/models?category=${carData?.series.mobilityCategory}&type=${carData?.series.mobilityType}`, {
            params: {
                page: 0,
                size: 8
            }
        })
        setSimilarCars(response.data.content);
    }

    useEffect(() => {
        fetchCarData();
        window.scrollTo({top: 0, left: 0, behavior: "instant"});
    }, [id]);

    useEffect(() => {
        getSimilarCars();
    }, [carData]);

    if (loading) {
        return <div>{t('mobilityModelDetail.loading')}</div>;
    }

    if (!carData) {
        return <div>{t('mobilityModelDetail.car_data_not_found')}</div>;
    }

    const carDetails = carData.detailsJson ? JSON.parse(carData.detailsJson) : {};

    const categorizedParameters = carData.parameters.reduce((acc, param) => {
        if (!acc[param.category]) {
            acc[param.category] = [];
        }
        acc[param.category].push({name: param.parameterName, value: param.parameterValue});
        return acc;
    }, {});

    const handleActiveIndex = (index) => {
        setActiveIndex(index);
    };

    const renderDetailsSection = (title, data) => (
        <div className={styles.customTabContent}>
            <h3>{title}</h3>
            <Table striped bordered hover className={styles.detailsTable}>
                <thead>
                <tr>
                    <th>{t('mobilityModelDetail.parameter_name')}</th>
                    <th>{t('mobilityModelDetail.value')}</th>
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.value}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );

    return (
        <div className={styles.mobilityModelDetailContainer} key={id} id="top">
            <div className={styles.detailsText}>
                <Link to={`/product`}>Product</Link>
                <span> / </span>
                <Link
                    to={`/product?category=${carData.series.mobilityCategory}`}>{carData.series.mobilityCategory}</Link>
                <span> / </span>
                <Link
                    to={`/product/?category=${carData.series.mobilityCategory}&type=${carData.series.mobilityType}`}>{carData.series.mobilityType}</Link>
            </div>
            <hr/>
            <div className={styles.modelDetails}>
                <div className={styles.detailsLeft}>
                    <h2 className={styles.mobileModelName}>{carData.modelName}</h2>
                    <div className={styles.carouselContainer}>
                        <Carousel className={styles.carCarousel} indicators={false} activeIndex={activeIndex}
                                  onSelect={handleActiveIndex}>
                            {carData.images.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img className="d-block w-100" src={image.imageUrl}
                                         alt={t('mobilityModelDetail.car_image', {index: index + 1})}/>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className={styles.carouselViewContainer}>
                        {carData.images.map((image, index) => (
                            <div key={index} onClick={() => handleActiveIndex(index)}>
                                <img
                                    className={`d-block w-100`}
                                    src={image.imageUrl}
                                    alt={t('mobilityModelDetail.car_image', {index: index + 1})}
                                    style={{border: index === activeIndex ? `2px solid #000000` : ``}}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.detailsRight}>

                    <div className={styles.container}>
                        <div className={styles.detailsText}>
                            <h3 style={{textAlign: "center"}}>{carData.modelName}</h3>
                        </div>
                        <div className={styles.detailsText}>
                            <span>{t('mobilityModelDetail.year')}: </span>{carData.year}
                        </div>
                        <div className={styles.detailsText}>
                            <span>{t('mobilityModelDetail.price')}: </span>{carData.price === 0 ? t('mobilityModelDetail.price_on_request') : carData.price}
                        </div>
                    </div>

                    <div className={`${styles.container} ${styles.inquire}`} style={{marginTop: "10px"}}>
                        <Inquire model={carData}/>
                    </div>
                </div>
            </div>
            <Tabs id="controlled-tab-example" activeKey={tabKey} onSelect={(k) => setTabKey(k)} className="mb-3 mt-3">
                <Tab eventKey="specification" title={t('mobilityModelDetail.specifications')}>
                    <div className={styles.additionalDetails}>
                        {carDetails.additionalTabs &&
                            carDetails.additionalTabs.map((tab, index) =>
                                renderDetailsSection(
                                    tab.title || t('mobilityModelDetail.additional_tab', {index: index + 1}),
                                    tab.data || []
                                )
                            )}
                        <div className={styles.tabsSection}>
                            <Tabs defaultActiveKey={Object.keys(categorizedParameters)[0]}
                                  className={styles.customTabs}>
                                {Object.entries(categorizedParameters).map(([category, parameters], index) => (
                                    <Tab eventKey={category} title={category} key={index}>
                                        {renderDetailsSection(category, parameters)}
                                    </Tab>
                                ))}
                            </Tabs>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="description" title={t('mobilityModelDetail.description')}>
                    <div dangerouslySetInnerHTML={{__html: carData.description}} className={styles.modelDescription}/>
                </Tab>
            </Tabs>

            <div className={styles.similarCars}>
                <CarList cars={similarCars} title={"비슷한 차량"}/>
            </div>
        </div>
    );
};


export default MobilityModelDetail;
