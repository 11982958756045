import React, {useEffect, useState} from "react";
import "./Hero.css";
import {ComposableMap, Geographies, Geography, Marker, Graticule} from "react-simple-maps";
import {Button, Modal, Table} from "react-bootstrap";
import axios from "../../utils/axios";
import Counter from "../../utils/Counter";
import {useSelector, useDispatch} from "react-redux";
import {store, persistor, login, logout, handleShowLogin, handleShowSignup, handleShowForgotPassword,handleBack} from "../../store/store";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CustomPagenation from "../../utils/CustomPagenation";
import {BUYER_COUNT, COUNTRY_COUNT} from "../../constants";

const Hero = () => {
    // GeoJSON 데이터 생성
    const [features, setFeatures] = useState([]);
    const [tooltipContent, setTooltipContent] = useState("");
    const [tooltipPosition, setTooltipPosition] = useState({x: 0, y: 0});
    const [show, setShow] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [countryCount, setCountryCount] = useState([]);
    const [buyerCount, setBuyerCount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [countryData, setCountryData] = useState([]);
    const [page, setPage] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [geo, setGeo] = useState({});

    useEffect(() => {
        // JSON 파일을 fetch로 불러오기
        fetch("/features.json")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => setFeatures(data))
            .catch((error) => console.error("Error fetching the JSON file:", error));
        getBuyerCountByCountry();

    }, []);

    useEffect(() => {
        getBuyerData(geo.id);
    },[geo,currentPage])
    const handleActivePage = (activePage) => {
        setCurrentPage(activePage -1);
    }
    const getBuyerData = async (country) => {
        try {
            const response = await axios.get("/api/buyers", {
                params: {
                    size: 10,
                    page: currentPage,
                    country: country
                }
            })
            setCountryData(response.data.content);
            setPage(response.data.page);
        }catch (e) {
            // console.log(e);
        }
    }

    const getBuyerCountByCountry = async () => {
        try{
            const response = await axios.get("/api/buyers/countryCount");
            setCountryCount(response.data);
        }catch (e) {
            // console.log(e);
        }
    }
    const hendleMouseEnter = (geo, evt) => {
        const countryName = geo.properties.name; // ISO Alpha-3 국가 코드
        const count = countryCount.find(country => country.country == geo.id)?.count || 0;
        setTooltipContent(countryName + "(" + count + ")");
        setTooltipPosition({x: evt.pageX -(window.innerWidth * 0.2), y: evt.pageY});
    }

    const handleClose = () => {
        setShow(false);
    }

    const showModal = (g) => {
        if(g.id != geo.id){
            setCurrentPage(0);
        }
        setGeo(g);
        setShow(true);
        setModalHeader(g.properties.name);
    }
    return (
        <div className="hero-container">
            <Modal show={show} onHide={handleClose} size="xl" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>{t('hero.companyName')}</th>
                            <th>{t('hero.address')}</th>
                            <th>{t('hero.email')}</th>
                            <th>{t('hero.phone')}</th>
                            <th>{t('hero.website')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            countryData.map((country) => (
                                <tr>
                                    <td>{country.companyName}</td>
                                    <td>{country.address}</td>
                                    <td>{country.email}</td>
                                    <td>{country.phoneNumber}</td>
                                    <td>{country.website}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>

                    <CustomPagenation page={page} handleActivePage={handleActivePage}></CustomPagenation>
                </Modal.Body>
            </Modal>

            <div className="buyer-list-continer">
                {/* 툴팁 */}
                {tooltipContent && (
                    <div className="country-tooltip"
                         style={{
                             top: tooltipPosition.y + 10,
                             left: tooltipPosition.x + 10,
                         }}
                    >
                        {tooltipContent}
                    </div>
                )}
                <div className="buyer-list">
                    <ComposableMap className="world-map" width={1000} height={570} >
                        {/*<Graticule stroke="rgb(98, 154, 202)"></Graticule>*/}
                        <Geographies geography={features}>
                            {({geographies}) =>
                                geographies.map((geo) => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        stroke="rgb(3, 73, 126)"
                                        style={{
                                            default: {fill: "rgb(144, 233, 247)",outline: "none" },
                                            hover: {fill: "#F53", outline: "none"},
                                            pressed: {fill: "#E42", outline: "none"},
                                        }}
                                        onMouseEnter={(evt) => {
                                            hendleMouseEnter(geo, evt)
                                        }}
                                        onMouseLeave={() => {
                                            setTooltipContent(false);
                                        }}
                                        onClick={() => {
                                            showModal(geo)
                                        }}

                                    />
                                ))
                            }
                        </Geographies>
                    </ComposableMap>
                    <p>{t('hero.mapClickPrompt')}</p>
                    <div className="buyer-info">
                        <Counter start={0} end={COUNTRY_COUNT} duration={2000} fontSize={48}/>
                        <p style={{color : "red"}}>{t('hero.countries')}</p>
                        <Counter start={0} end={BUYER_COUNT} duration={2000} fontSize={48}/>
                        <p style={{color : "red"}}>{t('hero.buyers')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
