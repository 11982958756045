import React, {useEffect, useState} from 'react';
import './GlobalPartners.css'
import axios from "../../utils/axios";
import CustomPagenation from "../../utils/CustomPagenation";
import {useTranslation} from "react-i18next";
import Hero from "../../components/hero/Hero";

const GlobalPartners = () => {
    const [features, setFeatures] = useState({});
    const [selectedContinent, setSelectedContinent] = useState('Africa');
    const [selectedCountryCode, setSelectedCountryCode] = useState('DZA');
    const [countryList, setCountryList] = useState([]);
    const [buyerList, setBuyerList] = useState([]);
    const [page, setPage] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCountryName, setSelectedCountryName] = useState("Algeria");
    const {t, i18n} = useTranslation();
    const [countryCount, setCountryCount] = useState([]);

    const getCountries = async () => {
        const response = await axios.get(`api/buyers/${selectedContinent}/countries`);
        setCountryList(response.data);
    }

    const handleActivePage = (activePage) => {
        setCurrentPage(activePage);
    }

    const handleCountry = (countryCode, countryName) => {
        if (selectedCountryCode != countryCode) {
            if (currentPage === 1) {
                getBuyers(countryCode, countryName);
            } else {
                setCurrentPage(1);
            }
        }
        setSelectedCountryCode(countryCode);
        setSelectedCountryName(countryName);
    }

    const getBuyers = async (countryCode, name) => {

        const page = currentPage;
        if (selectedCountryCode != countryCode) {
            setCurrentPage(1);
        }

        setSelectedCountryCode(countryCode);
        setSelectedCountryName(name);
        const response = await axios.get(`api/buyers`, {
            params: {
                country: countryCode,
                page: currentPage - 1,
                size: 10
            }
        });
        setPage(response.data.page);
        setBuyerList(response.data.content);

    }

    const handleContinent = async (continent) => {
        setSelectedContinent(continent);
    }
    const getCountryCounter = async () => {
        const response = await axios.get(`api/buyers/continentCount`);
        setCountryCount(response.data);
    }
    useEffect(() => {
        getCountries();
    }, [selectedContinent]);

    useEffect(() => {
        getBuyers(selectedCountryCode, selectedCountryName)
    }, [currentPage])

    useEffect(() => {
        getCountryCounter();
    }, [])


    const [search, setSearch] = useState("");

    // 검색 필터링
    const filteredCountries = countryList.filter(e =>
        e.countryName.toLowerCase().includes(search.toLowerCase())
    );


    return (
        <div className="global-partners-container">
            {/*<div className="header-image">*/}
            {/*    <img src="/images/global-trade.jpg" alt="Global Business Network"/>*/}
            {/*    <div className="header-overlay">*/}
            {/*        <h1>{t("globalPartners.title")}</h1>*/}
            {/*        <p>{t("globalPartners.description")}</p>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Hero></Hero>

            {/*<h1>Global Partners</h1>*/}
            <div className="continent-container">
                {countryCount.map((e) => {
                    return <div className={`continent ${selectedContinent === e.continent ? 'selected' : ''}`}
                                onClick={() => handleContinent(e.continent)}>{e.continent}({e.countryCount})
                    </div>
                })}
            </div>

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search country..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            <div className="country-container">
                {filteredCountries.sort((a, b) => a.countryName.localeCompare(b.countryName)).map((e, i) => (
                    <div key={i} className={`country ${selectedCountryCode === e.country ? 'selected' : ''}`}
                         onClick={() => handleCountry(e.country, e.countryName)}>
                        <div className="m-auto w-100">
                            <span>{e.countryName}</span>
                            <span> ({e.count})</span>
                        </div>
                    </div>
                ))}
            </div>

            <h2 className="selected-country">{selectedCountryName}</h2>
            <div className="buyer-container">
                <table>
                    <thead>
                    <th>No.</th>
                    <th>Company Name</th>
                    <th>Address</th>
                    <th>Email</th>
                    <th>Tell</th>
                    <th>Web Site</th>
                    </thead>
                    <tbody>
                    {
                        buyerList.map((e, i) => {
                            return <tr>
                                <td data-label={"No."}>{i + 1}</td>
                                <td data-label={"Company Name"}>{e.companyName}</td>
                                <td data-label={"Address"}>{e.address}</td>
                                <td data-label={"Email"}>{e.email}</td>
                                <td data-label={"Tell"}>{e.phoneNumber}</td>
                                <td data-label={"Web Site"}>{e.website}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
            <CustomPagenation page={page} handleActivePage={handleActivePage} scrollToTop={false}></CustomPagenation>

        </div>
    );
};

export default GlobalPartners;