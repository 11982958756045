import React, {useEffect, useState} from "react";
import {Modal, Button, Form, Col, Row} from "react-bootstrap";
import Select from "react-select";
import Flag from "react-world-flags";
import {FaSearch} from "react-icons/fa"; // 돋보기 아이콘 추가
import {countries} from "./countryData"; // 각국 이름과 코드, 국기 정보
import axios from "../../utils/axios"; // axios 추가

const SignupModal = ({show, handleClose, handleBack}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState(""); // 인증코드 입력값
    const [isVerified, setIsVerified] = useState(false); // 이메일 인증 성공 여부
    const [verificationMessage, setVerificationMessage] = useState(""); // 인증 성공/실패 메시지

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [error, setError] = useState(null); // 회원가입 에러 처리 상태
    const [timeLeft, setTimeLeft] = useState(null); // 타이머(초 단위)
    const [isMenuOpen, setIsMenuOpen] = useState(false); // 국가 선택 드롭다운 열림 상태

    // 자동완성 해제
    const autoCompleteOff = {autoComplete: "off"};
    // 환경 변수에서 API URL 가져오기
    const apiUrl = process.env.REACT_APP_API_URL;

    // 타이머 감소 로직
    useEffect(() => {
        if (timeLeft === null) return;
        if (timeLeft <= 0) return;

        const timer = setInterval(() => {
            setTimeLeft((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    // 타이머 시작 (10분 = 600초)
    const startTimer = () => {
        setTimeLeft(600);
    };

    // 타이머 형식 (mm:ss)
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    // 이메일 인증코드 요청
    const sendVerificationEmail = async () => {
        if (!email) {
            alert("이메일을 입력해주세요.");
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/auth/sendMailSignup`, {email: email});
            if (response.status === 200) {
                alert("인증 메일이 전송되었습니다. 메일함을 확인하세요.");
                setIsVerified(false);
                setVerificationMessage("");
                setVerificationCode("");
                startTimer();  // 메일 전송 후 타이머 시작
            } else {
                console.log("인증 메일 발송 실패");
            }
        } catch (err) {
            console.log(err);
            alert("인증 메일 전송에 실패했습니다.");
        }
    };

    // 인증 코드 확인
    const verifyEmailCode = async () => {
        if (!verificationCode) {
            alert("인증코드를 입력해주세요.");
            return;
        }

        // 실제로 인증코드를 확인하는 API를 호출해야 합니다.
        try{
            const response = await axios.post(`/auth/mailVerification`, {
                email: email,
                code: verificationCode,
            });

            setIsVerified(true);
            setVerificationMessage("이메일 인증에 성공했습니다!");
        }catch (e) {
            setIsVerified(false);
            setVerificationMessage("인증코드가 잘못되었거나 시간이 만료되었습니다.");
        }
    };

    // 회원가입 처리
    const handleSignup = async (e) => {
        e.preventDefault();
        // 비밀번호 확인
        if (password !== confirmPassword) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }

        // 이메일 인증 확인
        if (!isVerified) {
            alert("이메일 인증이 완료되지 않았습니다.");
            return;
        }

        // 회원가입 API 요청
        try {
            const response = await axios.post(`auth/signup`, {
                name: name,
                email: email,
                password: password,
                country: selectedCountry?.value,
            });

            if (response.status === 200) {
                alert("회원가입이 완료되었습니다!");
                setName("");
                setEmail("");
                setPassword("");
                setConfirmPassword("");
                setSelectedCountry(null);
                setIsVerified(false);
                setVerificationMessage("");
                setTimeLeft(0);
                setVerificationCode("");
                handleBack();
            }
        } catch (err) {
            setError(err.response?.data || "회원가입에 실패했습니다. 다시 시도해주세요.");
        }
    };

    // 국가 옵션 커스터마이징 (국가명 + 국기)
    const customOption = (props) => {
        const {label, value, flag} = props.data;
        return (
            <div
                ref={props.innerRef}
                {...props.innerProps}
                className={`d-flex align-items-center p-2 ${
                    props.isFocused ? "bg-light" : ""
                }`}
                style={{
                    cursor: "pointer",
                }}
            >
                <Flag code={flag} style={{width: "24px", marginRight: "10px"}}/>
                <span>
          {label} ({value})
        </span>
            </div>
        );
    };

    // 선택된 국가 표시 커스터마이징 (국기와 함께)
    const customSingleValue = (props) => {
        const {label, flag} = props.data;
        return (
            <div className="d-flex align-items-center">
                <Flag code={flag} style={{width: "24px", marginRight: "10px"}}/>
                <span>{label}</span>
            </div>
        );
    };

    // 검색 가능성을 알리기 위한 placeholder와 아이콘
    const customPlaceholder = () => (
        <div className="d-flex align-items-center">
            <FaSearch style={{marginRight: "8px"}}/>
            <span>Search country...</span>
        </div>
    );

    // 드롭다운 열림/닫힘 상태
    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <div id="login-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleSignup}>
                        {/* 이름 */}
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                {...autoCompleteOff}
                            />
                        </Form.Group>

                        {/* 이메일 */}
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Row>
                                <Col sm={8}>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        {...autoCompleteOff}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Button
                                        variant="primary"
                                        onClick={sendVerificationEmail}
                                        disabled={timeLeft > 0 && !isVerified}
                                    >
                                        인증 메일 전송
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* 인증코드 입력 */}
                        {timeLeft !== null && timeLeft > 0 && !isVerified && (
                            <Form.Group className="mt-2">
                                <Row>
                                    <Col sm={7}>
                                        <Form.Control
                                            type="text"
                                            placeholder="인증코드 입력"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            {...autoCompleteOff}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <Button variant="secondary" onClick={verifyEmailCode}>
                                            인증하기
                                        </Button>
                                    </Col>
                                    <Col sm={2}>
                                        <div style={{lineHeight: "2.4"}}>
                                            {timeLeft > 0 ? formatTime(timeLeft) : "00:00"}
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                        )}

                        {/* 이메일 인증 성공/실패 메시지 */}
                        {verificationMessage && (
                            <div
                                className={`mt-2 ${
                                    isVerified ? "text-success" : "text-danger"
                                }`}
                            >
                                {verificationMessage}
                            </div>
                        )}

                        {/* 국가 선택 */}
                        <Form.Group controlId="formBasicCountry" className="mt-3">
                            <Form.Label>Country</Form.Label>
                            <Select
                                options={countries}
                                value={selectedCountry}
                                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                                onMenuOpen={handleMenuOpen}
                                onMenuClose={handleMenuClose}
                                placeholder={customPlaceholder()}
                                components={{
                                    Option: customOption,
                                    SingleValue: customSingleValue,
                                }}
                                menuIsOpen={isMenuOpen}
                                required
                            />
                        </Form.Group>

                        {/* 비밀번호 */}
                        <Form.Group controlId="formBasicPassword" className="mt-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                {...autoCompleteOff}
                            />
                        </Form.Group>

                        {/* 비밀번호 확인 */}
                        <Form.Group controlId="formBasicConfirmPassword" className="mt-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                {...autoCompleteOff}
                            />
                        </Form.Group>

                        {/* 회원가입 에러 메시지 */}
                        {error && <div className="text-danger mt-2">{error}</div>}

                        {/* 회원가입 버튼 */}
                        <Button
                            variant="primary"
                            type="submit"
                            className="mt-3"
                            disabled={!isVerified} // 인증이 안 된 경우 비활성화
                        >
                            Sign Up
                        </Button>
                    </Form>

                    <Button variant="secondary" onClick={handleBack} className="mt-3">
                        Back to Login
                    </Button>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default SignupModal;
