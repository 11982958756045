import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./QnA.css";
import ContactForm from "../../components/contact/ContactForm";

const QnA = () => {
  const { t } = useTranslation();
  const [openIndices, setOpenIndices] = useState([]);

  const toggleAnswer = (index) => {
    setOpenIndices((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const questionsAndAnswers = t("qna.questionsAndAnswers", { returnObjects: true });

  return (
      <div className="qna-container">
        <h1 className="qna-title">{t("qna.title")}</h1>
        {questionsAndAnswers.map((qa, index) => (
            <div key={index} className="qna-item">
              <div className="qna-question" onClick={() => toggleAnswer(index)}>
                {qa.question}
              </div>
              {openIndices.includes(index) && (
                  <div className="qna-answer">
                    {qa.answer.split("\n").map((line, i) => (
                        <p key={i}>{line}</p>
                    ))}
                  </div>
              )}
            </div>
        ))}

        <div>
          <span className={"more-question"}>더 궁금하신 내용은 문의하기로 질문해주시면 답변해드리겠습니다.</span>
            <ContactForm></ContactForm>
        </div>
      </div>
  );
};

export default QnA;
