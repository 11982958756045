import React, {useState, useEffect, useRef} from "react";
import {Modal, Button, Form, Row, Col} from "react-bootstrap";
import axios from "../../utils/axios";

const ForgotPasswordModal = ({show, handleClose, handleBack}) => {
    // ----- 상태값들 -----
    const [step, setStep] = useState(1); // 현재 단계(1: 이메일입력, 2: 인증코드, 3: 새 비밀번호)
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [isVerified, setIsVerified] = useState(false); // 이메일 인증 성공 여부
    const [emailSent, setEmailSent] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    // 새 비밀번호 & 확인
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // 포커스 제어용
    const emailInputRef = useRef(null);

    useEffect(() => {
        if (show) {
            // 모달이 열릴 때 기본값 초기화
            setStep(1);
            setEmail("");
            setCode("");
            setIsVerified(false);
            setEmailSent(false);
            setResetSuccess(false);
            setNewPassword("");
            setConfirmPassword("");
            // 이메일 입력창에 포커스
            if (emailInputRef.current) {
                emailInputRef.current.focus();
            }
        }
    }, [show]);

    // ====== 1. 이메일 입력 후 인증 메일 전송 ======
    const handleSendEmail = async (e) => {
        e.preventDefault();
        if (!email) return alert("이메일을 입력해주세요.");

        // TODO: 실제로 이메일 인증코드 발송 API를 호출
        try {
            const response = await axios.post("/auth/sendMailForgotPassword", {email: email});
            setEmailSent(true);

        } catch (e) {
            setEmailSent(false);
        }


        // 다음 단계(2단계: 인증코드 입력)로 이동
        setStep(2);
    };

    // ====== 2. 인증코드 입력 후 검증 ======
    const handleVerifyCode = async (e) => {
        e.preventDefault();
        if (!code) return alert("인증코드를 입력해주세요.");

        // TODO: 실제 인증코드 검증 API를 호출해야 함
        try {
            const response = await axios.post("/auth/mailVerification", {email: email, code: code});
            setIsVerified(true);
            setStep(3);
        } catch (e) {
            alert("인증코드가 올바르지 않거나 만료되었습니다.");
        }
    };

    // ====== 3. 새 비밀번호 입력 후 재설정 ======
    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }

        try {
            const response = await axios.post("/auth/resetPassword", {
                email: email,
                password: newPassword,
            });
            setResetSuccess(true);
            setTimeout(handleBack, 1500);
        } catch (e) {
            alert("변경 도중 문제가 발생했습니다.");
        }
    };

    // 스텝별 화면을 분리해서 표시
    const renderStepContent = () => {
        // ----- STEP 1: 이메일 입력 -----
        if (step === 1) {
            return (
                <Form onSubmit={handleSendEmail}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            ref={emailInputRef}
                            autoComplete="off"
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        Send Verification Code
                    </Button>
                </Form>
            );
        }

        // ----- STEP 2: 인증코드 입력 -----
        if (step === 2) {
            return (
                <Form onSubmit={handleVerifyCode}>
                    <Form.Group controlId="formBasicCode">
                        <Form.Label>Verification Code</Form.Label>
                        <Row>
                            <Col sm={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter verification code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    autoComplete="off"
                                    required
                                />
                            </Col>
                            <Col sm={4}>
                                <Button variant="primary" type="submit">
                                    Verify
                                </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                    {emailSent && (
                        <div className="mt-3">
                            <p>
                                An email with a verification code has been sent to <b>{email}</b>.
                                <br/>
                                Please check your inbox.
                            </p>
                        </div>
                    )}
                </Form>
            );
        }

        // ----- STEP 3: 새 비밀번호 입력 -----
        if (step === 3) {
            return (
                <Form onSubmit={handleResetPassword}>
                    <Form.Group controlId="formNewPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword" className="mt-2">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        Reset Password
                    </Button>

                    {resetSuccess && (
                        <div className="mt-3 text-success">
                            <p>Password has been reset successfully!</p>
                        </div>
                    )}
                </Form>
            );
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderStepContent()}
                <Button variant="secondary" onClick={handleBack} className="mt-3">
                    Back to Login
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default ForgotPasswordModal;
