import React, { useEffect, useState } from "react";
import "./Counter.css";

const Counter = ({ start = 0, end = 3000, duration = 3000 , fontSize = "24"}) => {
    const [currentValue, setCurrentValue] = useState(start);

    useEffect(() => {
        let startTime = null;

        const animateCounter = (currentTime) => {
            if (!startTime) startTime = currentTime;
            const progress = Math.min((currentTime - startTime) / duration, 1);
            const value = Math.floor(progress * (end - start) + start);
            setCurrentValue(value);

            if (progress < 1) {
                requestAnimationFrame(animateCounter);
            }
        };

        requestAnimationFrame(animateCounter);
    }, [start, end, duration]);

    return (
            <span className="counter" style={{fontSize:fontSize+"px"}}>{currentValue}+</span>
    );
};

export default Counter;
