import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import { useSearchParams } from "react-router-dom";

const CustomPagenation = ({ page, handleActivePage,scrollToTop = true }) => {
    const [pageItems, setPageItems] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    // 페이지 이동 후 스크롤을 최상단으로 올리는 함수
    const handlePageChange = (pageNumber ) => {
        handleActivePage(pageNumber);
        // 스크롤을 부드럽게 맨 위로 이동
        if(scrollToTop){
            window.scrollTo({ top: 0, behavior: "instant" });
        }
        // 만약 전체 새로고침을 원한다면 아래 코드를 사용하세요.
        // window.location.reload();
    };

    useEffect(() => {
        if (!page || !page.totalPages) return;

        const currentPage = page.number + 1; // `page.number`는 0부터 시작하므로 +1
        const startNum = Math.max(1, currentPage - 5);
        const endNum = Math.min(page.totalPages, currentPage + 5);

        const paginationItems = Array.from(
            { length: endNum - startNum + 1 },
            (_, i) => {
                const number = startNum + i;
                return (
                    <Pagination.Item
                        key={number}
                        active={number === currentPage}
                        onClick={() => handlePageChange(number)}
                    >
                        {number}
                    </Pagination.Item>
                );
            }
        );

        setPageItems(paginationItems);
    }, [page, handleActivePage]);

    if (!page || !page.totalPages) return null;

    return (
        <div className="custom-pagenation">
            <Pagination>
                {page.number > 0 && (
                    <>
                        <Pagination.First onClick={() => handlePageChange(1)} />
                        <Pagination.Prev onClick={() => handlePageChange(page.number)} />
                    </>
                )}
                {pageItems}
                {page.number + 1 < page.totalPages && (
                    <>
                        <Pagination.Next
                            onClick={() => handlePageChange(page.number + 2)}
                        />
                        <Pagination.Last
                            onClick={() => handlePageChange(page.totalPages)}
                        />
                    </>
                )}
            </Pagination>
        </div>

    );
};

export default CustomPagenation;
