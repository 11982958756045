import {React, useEffect, useState} from "react";
import "./Footer.css";
import {Link} from "react-router-dom";
import Flag from "react-world-flags";
import {useTranslation} from "react-i18next";
import {CONTACT_INFO} from "../../constants";
import axios from "../../utils/axios";

const Footer = () => {
  const [t] = useTranslation();
  const [classification, setClassification] = useState([]);
  const fetchMobilityClassification = async () =>{
    const response = await axios.get("/api/mobilityClassification");
    setClassification(response.data);
  }

  useEffect(() =>{
    fetchMobilityClassification();
  },[])
  return (
    <footer className="footer reduced-footer">
      <div className="footer-content">
        <div className="footer-item">
          <h3>{t("navbar.contact")}</h3>
          <div className="footer-item-content">
            <p>Email: {CONTACT_INFO.EMAIL.JJMOTORS}</p>
            <p>Email: {CONTACT_INFO.EMAIL.CHINA}</p>
            <p><Flag code="cn" size="32" /> Phone: {CONTACT_INFO.PHONE.CHINA}</p>
            <p><Flag code="kr" size="32" /> Phone: {CONTACT_INFO.PHONE.KOREA}</p>
          </div>
        </div>
        <div className="footer-menu">
          {
            classification.map(e => {
              return (<div className="footer-category">
                <h5>{e.category}</h5>
                {e.types.map(t => {
                  return <p><Link to="/product?category=Car&type=MPV">{t}</Link></p>
                })}
              </div>)
            })
          }
          <div className="footer-category d-flex flex-column">
            <p><Link to="/globalPartners" className="nav-link">{t("navbar.globalPartners")}</Link></p>
            <p><Link to="/qna" className="nav-link">{t("navbar.qna")}</Link></p>
            {/*<h4>{t("navbar.about")}</h4>*/}
           <p><Link to="/about" className="nav-link">{t("navbar.aboutJJMotors")}</Link></p>
           <p><Link to="/contact" className="nav-link">{t("navbar.contact")}</Link></p>
           <p><Link to="/gallery" className="nav-link">{t("navbar.gallery")}</Link></p>
          </div>
        </div>
        <div className="footer-item">
          <h3>{t("hero.address")}</h3>
          <div className="footer-item-content">
            <p>경기도 용인시 기흥구 구성로</p>
            <p>64번길 29 204호</p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; Copyright 2024 JJ MOTORS Trading Co.,Ltd All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
