import React, {useState, useEffect} from "react";
import {Container, Row, Col, Form, Button, Alert} from "react-bootstrap";
import axios from "../../utils/axios";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../model/MobilityModelUpload.module.css";
import {Typeahead} from "react-bootstrap-typeahead";

const AddSeries = () => {
    const [seriesName, setSeriesName] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [description, setDescription] = useState("");
    const [brands, setBrands] = useState([]); // 브랜드 목록
    const [selectedBrand, setSelectedBrand] = useState(""); // 선택된 브랜드 ID
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("Electric Car");
    const [mobilityTypeList, setMobilityTypeList] = useState([]);
    const [mobilityCategoryList, setMobilityCategoryList] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        fetchMobilityClassification();
        fetchBrands();
    }, [])


    const fetchMobilityClassification = async () => {
        const response = await axios.get("/api/mobilityClassification");

        setMobilityTypeList([]);
        setMobilityCategoryList([])
        response.data.map(e => setMobilityCategoryList(prevState => [...prevState,e.category]));
        response.data.map(e => setMobilityTypeList(prevState => ({...prevState,[e.category] : e.types})));
    }

    const createOption = (list) =>{
        return list.map((e,i) =>{
            return <option value={e}>{e}</option>;
        })
    }

    const fetchBrands = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/brands`);
            setBrands(response.data); // 브랜드 데이터를 상태로 저장
            console.log(response.data);
        } catch (error) {
            console.error("브랜드 데이터를 가져오는 중 오류가 발생했습니다:", error);
            setErrorMessage("브랜드 데이터를 불러오는 중 오류가 발생했습니다.");
        }
    };

    const handleCategory = (evt) => {
        setSelectedCategory(evt.target.value);
    }

    const handleType = (evt) =>{
        setSelectedType(evt.target.value);
    }

    const handleBrand = (brand) =>{
        if (brand && brand.length > 0) {
            setSelectedBrand(brand[0].id)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage(""); // 오류 메시지를 초기화

        if (!seriesName || !selectedType || !selectedBrand) {
            setErrorMessage("모든 필수 항목을 입력해 주세요.");
            return;
        }

        const newSeries = {
            seriesName : seriesName,
            mobilityType: selectedType,
            description : description,
            brandId: selectedBrand, // 선택된 브랜드 ID만 전달
            mobilityCategory: selectedCategory
        };

        try {
            const response = await axios.post(`${apiUrl}/api/series`, newSeries);

            if (response.status === 201) {
                alert("업로드 성공 !!");
                navigate("/admin/series");
            } else {
                setErrorMessage("시리즈 추가 중 문제가 발생했습니다.");
            }
        } catch (error) {
            // catch 블록: 요청 실패 시 처리
            console.error("시리즈 추가 오류:", error);

            // 오류 처리: 서버에서 받은 응답 데이터가 있는 경우
            if (error.response) {
                setErrorMessage(
                    `오류: ${
                        error.response.data.message || "시리즈 추가 중 오류가 발생했습니다."
                    }`
                );
            } else if (error.request) {
                setErrorMessage("서버로부터 응답이 없습니다.");
            } else {
                setErrorMessage("시리즈 추가 중 오류가 발생했습니다.");
            }
        }
    };

    const handleCancel = () => {
        navigate("/admin/series-list"); // 시리즈 목록 페이지로 이동
    };

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-4">시리즈 추가</h1>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}


            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formSeriesName">
                            <Form.Label>시리즈 이름</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="시리즈 이름을 입력하세요"
                                value={seriesName}
                                onChange={(e) => setSeriesName(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formMobilityType">
                            <Form.Label>모빌리티 카테고리</Form.Label>
                            <Form.Select defaultValue="Electric Car" value={selectedCategory} onChange={handleCategory}>
                                {
                                    mobilityCategoryList.map(e => {return <option value={e}>{e}</option>})
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formMobilityType">
                            <Form.Label>모빌리티 타입</Form.Label>
                            <Form.Select onChange={handleType}>
                                {
                                    Object.keys(mobilityTypeList).length > 0  ?
                                    mobilityTypeList[selectedCategory].map(e => {return <option value={e}>{e}</option>}) : ""
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formDescription">
                            <Form.Label>설명</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="시리즈 설명을 입력하세요"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formBrand">
                            <Form.Label>브랜드 선택</Form.Label>
                            <Typeahead
                                id="brand-select"
                                // 표시할 텍스트 설정 (labelKey)
                                labelKey={(brand) =>
                                    `${brand.brandName}`
                                }
                                onChange={handleBrand}
                                // 검색 대상 리스트
                                options={brands.sort((a,b) => a.brandName.localeCompare(b.brandName)) || []}
                                // 이미 선택된 값 세팅
                                // selected={
                                //     selectedBrand
                                // }
                                // placeholder 설정
                                placeholder={"시리즈를 선택하세요"}
                                // true면 검색 시 필터링 + 선택값 지울 수 있는 x버튼 표시
                                // clearButton
                                className={styles.formControl}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" type="submit">
                    추가
                </Button>
                <Button variant="secondary" onClick={handleCancel} className="ms-2">
                    취소
                </Button>
            </Form>
        </Container>
    );
};

export default AddSeries;
