import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {EditorContent, useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Youtube from "@tiptap/extension-youtube";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import {Button, Container, Form} from "react-bootstrap";
import styles from './PostCreate.module.css';
import axios from "../../utils/axios";
import {useTranslation} from "react-i18next";
import DOMPurify from "dompurify";
import {
    FaBold,
    FaItalic,
    FaUnderline,
    FaStrikethrough,
    FaListUl,
    FaListOl,
    FaImage,
    FaYoutube,
    FaLink,
    FaAlignLeft,
    FaAlignCenter,
    FaAlignRight,
} from 'react-icons/fa';

const PostCreate = () => {
    const [title, setTitle] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("FreeBoard");
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const {t} = useTranslation();

    const editor = useEditor({
        extensions: [
            StarterKit,
            Image,
            Youtube.configure({
                width: 640,
                height: 480,
                allowFullscreen: true,
            }),
            Link.configure({
                openOnClick: false,
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
        ],
        content: '',
    });

    const handleImageUpload = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    editor.chain().focus().setImage({src: reader.result}).run();
                };
                reader.readAsDataURL(file);
            }
        };
    };

    const handleYoutubeEmbed = () => {
        const url = prompt("Enter the YouTube URL");
        if (url) {
            editor.commands.setYoutubeVideo({src: url});
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const content = DOMPurify.sanitize(editor.getHTML(), {
            ALLOWED_TAGS: ["p", "b", "i", "u", "a", "img", "ul", "ol", "li", "br"],
            ALLOWED_ATTR: ["href", "src", "alt", "title", "style"],
        });

        const postData = {
            title,
            content,
            category: selectedCategory,
        };

        try {
            const response = await axios.post(`${apiUrl}/api/post`, postData);
            if (response.status === 200) {
                alert("Post created successfully!");
                navigate("/post");
            }
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    return (
        <Container className={styles.formContainer}>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="category" className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value="FreeBoard">{t(`postList.categories.FreeBoard`)}</option>
                        <option value="PartsReview">{t(`postList.categories.PartsReview`)}</option>
                        <option value="TestDriveReview">{t(`postList.categories.TestDriveReview`)}</option>
                        <option value="QnABoard">{t(`postList.categories.QnABoard`)}</option>
                        <option value="InformationBoard">{t(`postList.categories.InformationBoard`)}</option>
                        <option value="UsedCarMarketplace">{t(`postList.categories.UsedCarMarketplace`)}</option>
                        {/*<option value="CarNews">{t(`postList.categories.CarNews`)}</option>*/}
                        {/*<option value="EventsMeetups">{t(`postList.categories.EventsMeetups`)}</option>*/}
                        {/*<option value="CommunityTipsRecommendations">{t(`postList.categories.CommunityTipsRecommendations`)}</option>*/}
                        <option value="MechanicAdvice">{t(`postList.categories.MechanicAdvice`)}</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="title" className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter the title"
                        maxLength={50}
                        required
                    />
                </Form.Group>

                <div className={styles.toolbar}>
                    <Button title="Bold" onClick={() => editor.chain().focus().toggleBold().run()}
                            className={styles.toolbarButton}>
                        <FaBold/>
                    </Button>
                    <Button title="Italic" onClick={() => editor.chain().focus().toggleItalic().run()}
                            className={styles.toolbarButton}>
                        <FaItalic/>
                    </Button>
                    <Button title="Underline" onClick={() => editor.chain().focus().toggleUnderline().run()}
                            className={styles.toolbarButton}>
                        <FaUnderline/>
                    </Button>
                    <Button title="Strikethrough" onClick={() => editor.chain().focus().toggleStrike().run()}
                            className={styles.toolbarButton}>
                        <FaStrikethrough/>
                    </Button>
                    <Button title="Bullet List" onClick={() => editor.chain().focus().toggleBulletList().run()}
                            className={styles.toolbarButton}>
                        <FaListUl/>
                    </Button>
                    <Button title="Ordered List" onClick={() => editor.chain().focus().toggleOrderedList().run()}
                            className={styles.toolbarButton}>
                        <FaListOl/>
                    </Button>
                    <Button title="Align Left" onClick={() => editor.chain().focus().setTextAlign('left').run()}
                            className={styles.toolbarButton}>
                        <FaAlignLeft/>
                    </Button>
                    <Button title="Align Center" onClick={() => editor.chain().focus().setTextAlign('center').run()}
                            className={styles.toolbarButton}>
                        <FaAlignCenter/>
                    </Button>
                    <Button title="Align Right" onClick={() => editor.chain().focus().setTextAlign('right').run()}
                            className={styles.toolbarButton}>
                        <FaAlignRight/>
                    </Button>
                    <Button title="Upload Image" onClick={handleImageUpload} className={styles.toolbarButton}>
                        <FaImage/>
                    </Button>
                    <Button title="Embed YouTube Video" onClick={handleYoutubeEmbed} className={styles.toolbarButton}>
                        <FaYoutube/>
                    </Button>
                    <Button title="Insert Link" onClick={() => editor.chain().focus().toggleLink().run()}
                            className={styles.toolbarButton}>
                        <FaLink/>
                    </Button>
                </div>

                <div className={styles.editorContainer} onClick={() => editor.commands.focus()}>
                    <EditorContent editor={editor} width={"100%"} height={"100%"} />
                </div>

                <Button variant="primary" type="submit" className="w-100 mt-3">
                    Submit Post
                </Button>
            </Form>
        </Container>
    );
};

export default PostCreate;
