import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import "./i18n"; // i18n 초기화 파일 가져오기
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID;

// 개발 환경에서만 경고 및 오류 메시지를 무력화하고 싶을 때
if (process.env.NODE_ENV !== "development") {
    console.warn = () => {};
    console.error = () => {};
}

root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={clientId}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </GoogleOAuthProvider>
    </React.StrictMode>
);

// 성능 측정
// reportWebVitals(console.log);
