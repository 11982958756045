import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./CarItem.css";
import {Link} from "react-router-dom";

const CarItem = ({car}) => {
    return (

        <Card id="car-item" className="m-auto mb-2">
            <Link to={`/mobilityModel/${car.id}`}>
                <Card.Img variant="top" src={car.thumbnailImage} className="car-item-image"/>
                <Card.Body>
                    <Card.Title><span>{car.modelName}</span> </Card.Title>
                    <div className="card-meta" >
                        <Card.Text>
                            {car.year == 0 ? "" : <span>{car.year}</span>}
                        </Card.Text>
                        <Card.Text>
                            <span>{car.series.mobilityType}</span>
                        </Card.Text>
                    </div>

                    {/*{car.price == 0 ? "" :<Card.Text><span>가격 : ${car.price.toLocaleString()}</span></Card.Text>}*/}

                </Card.Body>
            </Link>
        </Card>

    );
};

export default CarItem;
