import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout, handleShowLogin} from "../../store/store";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faGlobe, faTachometerAlt, faDoorOpen} from "@fortawesome/free-solid-svg-icons";
import {getDecodedToken} from "../../utils/jwtUtils";
import axios from "../../utils/axios";  // CSS 모듈 import
import "./NavBar.css"

function NavBar() {
    const {t, i18n} = useTranslation();
    const [isLngMenuOpen, setIsLngMenuOpen] = useState(false);
    const [userMenuDropdown, setUserMenuDropdown] = useState(false);
    let auth = useSelector((state) => state.auth);
    let dispatch = useDispatch();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isProductOpen, setIsProductOpen] = useState(false);
    const [isAboutOpen, setIsAboutOpen] = useState(false);

    const [isMobilityCategoryOpen, setIsMobilityCategoryOpen] = useState({});
    const [isElectricCarOpen, setIsElectricCarOpen] = useState(false);
    const [isElectricBikeOpen, setIsElectricBikeOpen] = useState(false);
    const [isExcavatorOpen, setIsExcavatorOpen] = useState(false);
    const [isTruckOpen, setIstruckOpen] = useState(false);
    const [isVanOpen, setIsVanOpen] = useState(false);
    const [isCarOpen, setIsCarOpen] = useState(false);
    const isAdmin = getDecodedToken()?.role?.includes("ADMIN");
    const [classification, setClassification] = useState([]);

    useEffect(() =>{
        fetchMobilityClassification();
    },[])

    const fetchMobilityClassification = async () =>{
        const response = await axios.get("api/mobilityClassification");
        setClassification(response.data);
        const category = {};
        response.data.forEach((e) => {
            category[e.category] = false;
        });
        setIsMobilityCategoryOpen((prev) => ({
            ...prev,
            ...category,
        }));
    }

    const handleLngDropdown = () => {
        setIsLngMenuOpen((prev) => !prev);
        if (userMenuDropdown) {
            setUserMenuDropdown(false);
        }

        if(isMobileMenuOpen){
            setIsMobileMenuOpen(false);
        }
    }

    const handleUserMenuDropdown = () => {
        setUserMenuDropdown((prev) => !prev);
        if (isLngMenuOpen) {
            setIsLngMenuOpen(false);
        }
    }

    const handleLogout = () => {
        axios.post(`/auth/logout`, {}, { withCredentials: true });
        dispatch(logout());
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsLngMenuOpen((prev) => !prev);
    };

    const handleLinkClick = () =>{
        setIsMobileMenuOpen(false);
    }

    const handleMobileMenu = () =>{
        setIsMobileMenuOpen(!isMobileMenuOpen);
        setIsLngMenuOpen(false);
    }

    return (
        <div id="navbar">
            <div className="navbar-container">
                {/*왼쪽*/}
                <Link to="/" className="nav-logo"><img src="/images/Logo.png" alt="logo"/> <span translate="no">JJMotors</span> </Link>

                <div className={`nav-content`}>
                    <div className={`nav-dropdown `}>
                        <Link to="/product" className="nav-link">{t("navbar.product")} <i
                            className="bi bi-caret-down-fill"></i></Link>
                        <div className="dropdown-content">
                            {
                                classification.map((e,i) => {return <div className="dropdown-category">
                                    <Link to={`/product?category=${e.category}`} className="category-title">{e.category}</Link>
                                    <hr/>
                                    {e.types.map((t) => {
                                        return <Link to={`/product?category=${e.category}&type=${t}`}>{t}</Link>
                                    })}
                                </div> })
                            }
                        </div>
                    </div>
                    <Link to="/post" className="nav-link">{t("navbar.community")}</Link>
                    <Link to="/globalPartners" className="nav-link">{t("navbar.globalPartners")}</Link>
                    <Link to="/qna" className="nav-link">{t("navbar.qna")}</Link>
                    <div className="nav-dropdown">
                        <span className="nav-link">{t("navbar.about")} <i className="bi bi-caret-down-fill"></i></span>
                        <div className="dropdown-content">
                            <div className="dropdown-category">
                                <span className="category-title">{t("navbar.about")}</span>
                                <hr/>
                                <Link to="/about" className="nav-link">{t("navbar.aboutJJMotors")}</Link>
                                <Link to="/contact" className="nav-link">{t("navbar.contact")}</Link>
                                <Link to="/gallery" className="nav-link">{t("navbar.gallery")}</Link>
                            </div>
                        </div>
                    </div>
                    {isAdmin &&
                        <Link to="/admin/dashboard" className="nav-link" style={{width: "200px"}}>
                            <FontAwesomeIcon icon={faTachometerAlt}/> Admin Dashboard
                        </Link>
                    }
                </div>


                {/*오른쪽*/}
                {auth ?
                    <div className="nav-login" onClick={() => handleUserMenuDropdown()}>
                        <FontAwesomeIcon icon={faUser} />
                        <div className={`user-menu ${userMenuDropdown ? "open" : ""}`}>
                            <Link to="/user/profile"> {t("navbar.profile")}</Link>
                            <a onClick={() => handleLogout()}>{t("navbar.logout")}</a>
                        </div>

                    </div>
                    : <div className="nav-login" onClick={() => dispatch(handleShowLogin())}><FontAwesomeIcon
                        icon={faUser}/> <span>{t("navbar.login")}</span> </div>}

                <div className="lng-container">
                    <div className="nav-lng" onClick={() => handleLngDropdown()}><FontAwesomeIcon
                        icon={faGlobe}/> <span>{t("navbar.language")}</span> </div>
                    <div className={`lng-dropdown ${isLngMenuOpen ? "open" : ""}`}>
                        <div className="lng-element" onClick={() => changeLanguage("en")}>English (English)</div>
                        <div className="lng-element" onClick={() => changeLanguage("ko")}>한국어 (Korean)</div>
                        <div className="lng-element" onClick={() => changeLanguage("zh-CN")}>简体中文 (Simplified Chinese)</div>
                        <div className="lng-element" onClick={() => changeLanguage("es")}>Español (Spanish)</div>
                        <div className="lng-element" onClick={() => changeLanguage("ar")}>العربية (Arabic)</div>
                        <div className="lng-element" onClick={() => changeLanguage("ru")}>Русский (Russian)</div>
                        <div className="lng-element" onClick={() => changeLanguage("fr")}>Français (French)</div>
                        <div className="lng-element" onClick={() => changeLanguage("pt")}>Português (Portuguese)</div>
                        <div className="lng-element" onClick={() => changeLanguage("ja")}>日本語 (Japanese)</div>
                        <div className="lng-element" onClick={() => changeLanguage("hi")}>हिंदी (Hindi)</div>
                        <div className="lng-element" onClick={() => changeLanguage("ur")}>اردو (Urdu)</div>
                    </div>
                </div>
                {/*모바일용*/}
                <i className={`bi bi-list `} onClick={handleMobileMenu}></i>
                <div className={`mobile-navbar ${isMobileMenuOpen ? "open" : ""}`}>
                    <ul style={{marginBottom : "0px", paddingLeft : "0px"}}>
                        {/*<li>*/}
                            <Link onClick={() => setIsProductOpen(!isProductOpen)}>
                                {t("navbar.product")}
                                {
                                    isProductOpen ? <i className="bi bi-caret-up-fill"></i> :
                                        <i className="bi bi-caret-down-fill"></i>
                                }
                            </Link>
                        {/*</li>*/}

                        <li className={`m-product ${isProductOpen ? "open" : ""}`}>
                            <Link to="/product" onClick={handleLinkClick}>All</Link>
                            {classification.map(e => {
                                return  <ul>
                                    <Link onClick={() => setIsMobilityCategoryOpen((prev) => ({...prev,[e.category] : !prev[e.category]}))}>{e.category} {
                                        isMobilityCategoryOpen[e.category] ? <i className="bi bi-caret-up-fill"></i> :
                                            <i className="bi bi-caret-down-fill"></i>
                                    }</Link>

                                    {e.types.map(t => {
                                        return <li className={`${isMobilityCategoryOpen[e.category] ? "open" : ""}`}><Link to={`/product?category=${e.category}&type=${t}`}>{t}</Link></li>
                                    })}
                                </ul>
                            })}
                        </li>
                    </ul>
                    <ul style={{marginBottom : "0px", paddingLeft : "0px"}}>
                        <Link onClick={handleLinkClick} to="post">{t("navbar.community")}</Link>
                    </ul>
                    <ul style={{marginBottom : "0px", paddingLeft : "0px"}}>
                        <Link onClick={handleLinkClick} to="/globalPartners">{t("navbar.globalPartners")}</Link>
                    </ul>
                    <ul style={{marginBottom : "0px", paddingLeft : "0px"}}>
                       <Link onClick={handleLinkClick} to="/qna">{t("navbar.qna")}</Link>
                    </ul>
                    <ul style={{marginBottom : "0px", paddingLeft : "0px"}}>
                        <li><Link onClick={() => setIsAboutOpen(!isAboutOpen)}>
                            {t("navbar.about")}
                            {
                                isAboutOpen ? <i className="bi bi-caret-up-fill"></i> :
                                    <i className="bi bi-caret-down-fill"></i>
                            }
                        </Link></li>

                        <li className={`m-product ${isAboutOpen ? "open" : ""}`}><Link onClick={handleLinkClick} to="/about">{t("navbar.aboutJJMotors")}</Link></li>
                        <li className={`m-product ${isAboutOpen ? "open" : ""}`}><Link onClick={handleLinkClick} to="/contact">{t("navbar.contact")}</Link></li>
                        <li className={`m-product ${isAboutOpen ? "open" : ""}`}><Link onClick={handleLinkClick} to="/gallery">{t("navbar.gallery")}</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default NavBar;
