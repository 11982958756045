import React, {useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import "./SearchBox.css"

const SearchBox = () => {
    const [searchParams , setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(searchParams.get("search") || "");
    const navigate = useNavigate();
    const handleSearch = () => {
        // 실제 검색 로직 또는 페이지 이동 등
        navigate("/product?search=" + searchTerm);
    };

    // Enter키 감지 함수
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };
    return (
        // <div className="search-container">
        //     {/* 검색 박스 */}
            <div className="search-box">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button onClick={handleSearch}>Search</button>
            </div>
        // </div>
    );
};

export default SearchBox;