import axios from 'axios';
import { checkTokenExpiration } from '../utils/CheckTokenExpiration';
import { store } from '../store/store.js';
import i18next from 'i18next'; // i18next 임포트

// Axios 인스턴스 생성
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

// 요청 인터셉터 설정
api.interceptors.request.use(
    (config) => {
        // i18next로 설정된 언어 코드 추가
        const currentLanguage = i18next.language || 'en'; // 기본값을 'en'으로 설정
        config.headers['Accept-Language'] = currentLanguage;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response, // 정상 응답 그대로 반환
    (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            // localStorage.removeItem('token');
            // window.location.href = "/login"; // 로그인 페이지로 이동 (선택 사항)
        }
        return Promise.reject(error);
    }
);

export default api;
