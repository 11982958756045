import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Inquire.css';
import axios from "../../utils/axios";

const Inquire = ({ model }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        inquiryContent: "",
        modelId: model.id,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post("api/contact", formData);
            alert(t('inquire.inquiry_submitted'));
            setFormData({
                name: "",
                email: "",
                inquiryContent: "",
                modelId: model.id,
            });
        } catch (e) {
            alert(t('inquire.error_occurred'));
        }
    };

    return (
        <div className="inquiry-form-container">
            <h2 className="fw-bold">{t('inquire.product_inquiry')}</h2>
            <form onSubmit={handleSubmit}>
                <div className="text-start mb-2">
                    <span>{t('inquire.model_name')} :</span>
                    <input type="text" value={model.modelName} readOnly required />
                </div>
                <div className="flex f-row form-grid-container gap-2">
                    <div className="text-start">
                        <span>{t('inquire.name')}* </span>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder={t('inquire.your_name')}
                            required
                        />
                    </div>
                    <div className="text-start">
                        <span>{t('inquire.email')}* </span>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder={t('inquire.your_email')}
                            required
                        />
                    </div>
                </div>

                <label>
                    {t('inquire.inquiry_content')}:
                    <textarea name="inquiryContent" value={formData.inquiryContent} onChange={handleChange} placeholder={t('inquire.your_inquiry')} required></textarea>
                </label>

                <input type="hidden" name="modelId" value={model.modelId} />

                <button type="submit">{t('inquire.submit_inquiry')}</button>
            </form>
        </div>
    );
};

export default Inquire;
