import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import {FaThumbsUp} from "react-icons/fa";
import {formatDate} from "../../utils/formatDate";
import axios from "../../utils/axios";
import {countries} from "../user/countryData";
import {useTranslation} from "react-i18next";
import styles from "./PostList.module.css"

const PostList = (props) => {
    const {t} = useTranslation();
    const threeToTwoMap = countries.reduce((acc, { value, flag }) => {
        acc[value] = flag;
        return acc;
    }, {});

    function getTwoLetterCode(threeLetterCode) {
        // 매핑 객체에서 코드가 존재하지 않으면 빈 문자열("")을 반환
        return threeToTwoMap[threeLetterCode] || "";
    }

    useEffect(() => {

    },[props.posts])
    return (
        <div className={styles.contentContainer}>
            <ul className={styles.postList}>
                {props.posts.length > 0 ? (
                    props.posts.map((post) => (
                        <li key={post.id} className={`${styles.postItem} ${props.id === post.id ? styles.view : ""}` }>
                            <div className={styles.postInfoContainer}>
                                <div className={styles.postInfoLeft}>
                                    <span
                                        className={styles.postCategory}>{t(`postList.categories.${post.category}`)}</span>
                                    <Link to={`/post/${post.id}?page=${props.page}`} className={styles.postTitle}
                                          style={{textAlign: "left"}}>
                                        {post.title}
                                    </Link>
                                </div>
                                <div className={styles.postInfoRight}>
                                    <span className={styles.postAuthor}>{post.userName}</span>
                                    <span className={styles.postCountry}>
                                         <ReactCountryFlag
                                             countryCode={getTwoLetterCode(post.userCountry)}
                                             svg
                                             style={{
                                                 width: '25px',
                                                 height: '20px',
                                                 borderRadius: '2px'
                                             }}
                                             title={post.userCountry}
                                         />
                                        {post.userCountry}
                                    </span>
                                    {/*<span className={styles.viewCount}>{post.viewCount} {t('postList.views')}</span>*/}
                                    <span className={styles.viewCount}>{post.viewCount}</span>
                                    <span className={styles.likeCount}>
                                        <FaThumbsUp className={styles.likeIcon}/>
                                        {post.likeCount}
                                    </span>
                                    <span className={styles.postDate}>
                                        {formatDate(post.createdAt)}
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))
                ) : (
                    <li className={styles.emptyPostItem}>
                        <div>{t('postList.noPosts')}</div>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default PostList;