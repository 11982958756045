import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation(); // 현재 경로 감지

    useEffect(() => {
        window.scrollTo(0, 0); // 페이지 이동 시 스크롤 맨 위로 이동
    }, [pathname]); // pathname 변경될 때 실행

    return null;
};

export default ScrollToTop;
