import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faFlag, faEdit, faCamera } from '@fortawesome/free-solid-svg-icons';
import styles from './Myprofile.module.css';
import { countries } from "./countryData";
import Select from "react-select";
import Flag from "react-world-flags";
import { getDecodedToken } from "../../utils/jwtUtils";

const MyProfile = () => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const token = getDecodedToken();

    const [userData, setUserData] = useState({
        name: "",
        email: "",
        phone: "",
        country: ""
    });
    const [updatedUserData, setUpdatedUserData] = useState(userData);
    const fetchUserData = async () => {
        try {
            console.log(token);
            const response = await axios.get(`/user/${token.id}`);
            setUserData(response.data);
            setSelectedCountry(countries.find(c => c.value === response.data.country));
            setProfileImage(response.data.profileImageUrl); // 프로필 이미지 설정
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchUserData();
    }, [t]);

    const handleEditClick = () => {
        setIsEditing(true);
        setUpdatedUserData(userData);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedUserData({
            ...updatedUserData,
            [name]: value,
        });
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setUpdatedUserData({
            ...updatedUserData,
            country: selectedOption.value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
        setPreviewImage(URL.createObjectURL(file));
    };

    const handleConfirmClick = async () => {
        try {
            const formData = new FormData();
            formData.append('name', updatedUserData.name);
            formData.append('country', updatedUserData.country);
            if (profileImage) formData.append('profileImage', profileImage);

            const response = await axios.put(`/user/${token.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setUserData(response.data);
            setIsEditing(false);
            setPreviewImage(null);
        } catch (error) {
            console.error(error);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '38px',
            cursor: 'pointer',
            minWidth: '300px'
        }),
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: state.isFocused ? '#e6e6e6' : 'white',
            color: state.isFocused ? '#000' : 'black',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
        }),
        menu: (provided) => ({
            ...provided,
            marginTop: 0,
        }),
    };

    return (
        <div className={styles.profileContainer}>
            <h1 className={styles.title}>{t('profile')}</h1>

            <div className={styles.imageSection}>
                <img
                    src={previewImage || profileImage || '/images/default-profile.png'}
                    alt="Profile"
                    className={styles.profileImage}
                />
                {/*{isEditing && (*/}
                {/*    <label className={styles.cameraIcon}>*/}
                {/*        <FontAwesomeIcon icon={faCamera} />*/}
                {/*        <input type="file" onChange={handleImageChange} accept="image/*" className={styles.fileInput} />*/}
                {/*    </label>*/}
                {/*)}*/}
            </div>

            <div className={styles.infoSection}>
                <label className={styles.label}><FontAwesomeIcon icon={faUser} /> <span className={styles.labelText}>{t('name')}:</span></label>
                {isEditing ? (
                    <input
                        type="text"
                        name="name"
                        value={updatedUserData.name}
                        onChange={handleInputChange}
                        className={styles.input}
                    />
                ) : (
                    <span className={styles.value}>{userData.name}</span>
                )}
            </div>

            <div className={styles.infoSection}>
                <label className={styles.label}><FontAwesomeIcon icon={faEnvelope} /> <span className={styles.labelText}>{t('email')}:</span></label>
                <span className={styles.value}>{userData.email}</span>
            </div>

            <div className={styles.infoSection}>
                <label className={styles.label}><FontAwesomeIcon icon={faFlag} /> <span className={styles.labelText}>{t('country')}:</span></label>
                {isEditing ? (
                    <Select
                        options={countries}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                        styles={customStyles}
                        formatOptionLabel={(country) => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Flag code={country.flag} style={{ width: "24px", marginRight: "8px" }} />
                                <span>{country.label}</span>
                            </div>
                        )}
                        classNamePrefix="select"
                    />
                ) : (
                    <span className={styles.value}>
                        {selectedCountry ? (
                            <>
                                <Flag code={selectedCountry.flag} style={{ width: "24px", marginRight: "10px" }} />
                                {selectedCountry.label}
                            </>
                        ) : (
                            userData.country
                        )}
                    </span>
                )}
            </div>

            <div className={styles.buttonSection}>
                {isEditing ? (
                    <>
                        <button onClick={handleConfirmClick} className={styles.confirmButton}>
                            {t('confirm')}
                        </button>
                        <button onClick={() => setIsEditing(false)} className={styles.cancelButton}>
                            {t('cancel')}
                        </button>
                    </>
                ) : (
                    <button onClick={handleEditClick} className={styles.editButton}>
                        <FontAwesomeIcon icon={faEdit} /> {t('edit')}
                    </button>
                )}
            </div>
        </div>
    );
};

export default MyProfile;
