import React, {useState, useEffect, useRef} from "react";
import axios from "../../utils/axios";
import CarList from "../../components/car/CarList";
import "./MainPage.css";
import Carousel from "react-bootstrap/Carousel";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SearchBox from "../../components/search/SearchBox";
import {BUYER_COUNT, COUNTRY_COUNT} from "../../constants";

const MainPage = () => {
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [mainBanners, setMainBanners] = useState([""]);
    const [leftBanners, setLeftBanners] = useState([""]);
    const [rightBanners, setRightBanners] = useState([""]);
    const [reviews, setReviews] = useState([]);
    const [news, setNews] = useState([]);
    const navigate = useNavigate();
    const [leftAdHover, setLeftAdHover] = useState(false);
    const [rightAdHover, setRightAdHover] = useState(false);
    const [brands, setBrands] = useState([]);
    // i18next 사용
    const {t} = useTranslation();

    const formatDateUniversal = (isoString) => {
        const date = new Date(isoString);
        const options = {
            year: "numeric", month: "2-digit", day: "2-digit",
        };
        return new Intl.DateTimeFormat("ko-KR", options)
            .format(date)
            .replace(/\. /g, "-");
    };

    const fetchBrandList = async () => {
        try {
            const response = await axios.get("api/brands");
            setBrands(response.data.filter(e => e.brandName != "JJMOTORS" && e.brandName != "None" ));
        } catch (e) {

        }
    }

    const fetchRecentCars = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/models`, {
                params: {
                    page: 0, size: 8,
                },
            });
            setCars(response.data.content);
        } catch (err) {
            setError("자동차 정보를 불러오는 데 실패했습니다.");
        } finally {
            setLoading(false);
        }
    };

    const fetchBanners = async () => {
        try {
            const response = await axios.get(`api/banners?bannerType=Main`);
            const data = response.data.content;
            setMainBanners(data.filter((e) => e.bannerType === "Main"));
            setLeftBanners(data.filter((e) => e.bannerType === "LSide"));
            setRightBanners(data.filter((e) => e.bannerType === "RSide"));
        } catch (e) {
            // 오류 처리
        }
    };

    const fetchRss = async () => {
        try {
            const responseReview = await axios.get(`api/rss`, {
                params: {category: "review"},
            });
            const responseNews = await axios.get(`api/rss`, {
                params: {category: "news"},
            });
            setReviews(responseReview.data);
            setNews(responseNews.data);
        } catch (e) {
            console.error("Rss Feed Fail");
        }
    };

    useEffect(() => {
        fetchRss();
        fetchBanners();
        fetchRecentCars();
        fetchBrandList();
    }, []);

    const videoRefs = useRef([]);

    const stopAllVideos = () => {
        videoRefs.current.forEach(video => {
            if (video) {
                video.pause();
                video.currentTime = 0; // 처음으로 되감기
            }
        });
    };

    // 로딩/에러 메시지 등을 i18n으로 처리하고 싶다면 아래 주석도 바꿀 수 있습니다.
    // if (loading) return <p>{t("mainPage.loading")}</p>;
    // if (error) return <p>{t("mainPage.error")}: {error}</p>;

    return (<div className="main-container">
        {/* 메인 캐러셀 섹션 */}
        <div className="section main-carousel">
            <Carousel indicators={true} controls={true} interval={2000}>
                {mainBanners?.map((b) => (
                    <Carousel.Item key={b.id}>
                        <Link to={b.linkUrl}>
                            <img className="d-block w-100" src={b.imageUrl} alt={b.title}/>
                            <Carousel.Caption>
                                <h2 style={{fontWeight: "700"}}>{b.description}</h2>
                            </Carousel.Caption>
                        </Link>
                    </Carousel.Item>)) || ""}
            </Carousel>
        </div>
        <div className="d-flex justify-content-center">
            <SearchBox></SearchBox>
        </div>
        <div className="brand-scroller">
            {/* 브랜드 섹션 헤더 추가 */}
            <div className="brand-heading">
                <h2>Our Collaborative Brands</h2>
            </div>

            <div className="brand-container d-flex flex-row">
                {brands.map((b) => (<div key={b.brandName}>
                    <img src={b.logoUrl} alt={b.brandName}/>
                    <p>{b.brandName}</p>
                </div>))}
                {brands.map((b, index) => (<div key={`${b.brandName}-duplicate-${index}`}>
                    <img src={b.logoUrl} alt={b.brandName}/>
                    <p>{b.brandName}</p>
                </div>))}
            </div>
        </div>


        {/* 광고 이미지 섹션 */}
        <div className="section grid-container">
            <div
                className="grid-item advertisement"
                onMouseEnter={() => setLeftAdHover(true)}
                onMouseLeave={() => setLeftAdHover(false)}
            >
                <div className={`ad-overlay ${leftAdHover ? "show" : ""}`}>
                    <a href="https://jjmotors.net/product?search=jjmotors">
                        <p className="ad-overlay-text">{t("mainPage.learnMore")}</p>
                    </a>
                </div>
                <img src="/images/speed_model.JPG" alt="jjmotors-ad1"/>
            </div>
            <div
                className="grid-item advertisement"
                onMouseEnter={() => setRightAdHover(true)}
                onMouseLeave={() => setRightAdHover(false)}
            >
                {/*<div className={`ad-overlay ${rightAdHover ? "show" : ""}`}>*/}
                {/*    <a href="https://jjmotors.net/mobilityModel/224">*/}
                {/*        <p className="ad-overlay-text">{t("mainPage.learnMore")}</p>*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<img src="/images/power_model.jpg" alt="jjmotors-ad2"/>*/}
                <Carousel controls={true} indicators={true} interval={null} onSlide={stopAllVideos}>
                    {[1, 2, 3, 4, 5, 6].map((num, index) => (
                        <Carousel.Item key={index}>
                            <video
                                ref={el => videoRefs.current[index] = el}
                                src={`https://d1l0rqr6f7o97b.cloudfront.net/jjmotors_${num}.mp4`}
                                controls
                                width="100%"
                                height={314}
                            ></video>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </div>

        {/* 인기 차량 섹션 */}
        <div className="section">
            <h2 style={{textAlign: "left"}}>{t("mainPage.popularCars")}</h2>
            <CarList cars={cars}/>
        </div>

        {/* 리뷰 & 뉴스 섹션 */}
        <div className="section grid-container">
            {/* 리뷰 */}
            <div className="article-container">
                <h3>{t("mainPage.reviews")}</h3>
                <hr/>
                <div className="grid-item article">
                    {reviews.map((a, i) => (<a
                        key={`review_${i}`}
                        href={a.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="article-box">
                            <div className="article-image">
                                <img src={a.imageUrl} alt={"review_" + (i + 1)}/>
                            </div>
                            <div className="article-text">
                                <strong>{a.title}</strong>
                                <span>{a.rssDescription}</span>
                                <p>{formatDateUniversal(a.pubDate)}</p>
                            </div>
                        </div>
                    </a>))}
                </div>
            </div>
            {/* 뉴스 */}
            <div className="article-container">
                <h3>{t("mainPage.news")}</h3>
                <hr/>
                <div className="grid-item article">
                    {news.map((a, i) => (<a
                        key={`news_${i}`}
                        href={a.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="article-box">
                            <div className="article-image">
                                <img src={a.imageUrl} alt={"news_" + (i + 1)}/>
                            </div>
                            <div className="article-text">
                                <strong>{a.title}</strong>
                                <span>{a.rssDescription}</span>
                                <p>{formatDateUniversal(a.pubDate)}</p>
                            </div>
                        </div>
                    </a>))}
                </div>
            </div>
        </div>

        {/* about 섹션 */}
        <section className="about-section">
            <div className="overlay"></div>

            <div className="about-content">
                {/* "Why Choose JJMotors?" */}
                <h2>{t("mainPage.whyChooseJJMotors")}</h2>

                {/* HTML/태그가 포함된 문구는 dangerouslySetInnerHTML 활용 */}
                <p
                    dangerouslySetInnerHTML={{
                        __html: t("mainPage.connectingBuyersHtml"),
                    }}
                />

                {/* 신뢰성 강조 숫자 정보 */}
                <div className="stats-container">
                    <div className="stats-box">
                        <h3>{BUYER_COUNT}+</h3>
                        <p>{t("mainPage.buyers")}</p>
                    </div>
                    <div className="stats-box">
                        <h3>{COUNTRY_COUNT}+</h3>
                        <p>{t("mainPage.countriesServed")}</p>
                    </div>
                </div>

                {/* 버튼들 */}
                <button className="about-button" onClick={() => navigate("/about")}>
                    {t("mainPage.learnMoreAboutUs")}
                </button>
                <button className="about-button" onClick={() => navigate("/contact")}>
                    {t("mainPage.contactNow")}
                </button>
            </div>
        </section>
    </div>);
};

export default MainPage;
