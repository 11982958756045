import React, {useEffect, useState, useRef} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import axios from "../../utils/axios";
import {FaThumbsUp} from "react-icons/fa";
import styles from "./PostDetail.module.css";
import 'react-toastify/dist/ReactToastify.css';
import {getDecodedToken} from "../../utils/jwtUtils";
import toast from "react-hot-toast";
import {formatDate} from "../../utils/formatDate";
import {useTranslation} from "react-i18next";
import PostList from "../../components/post/PostList";
import CustomPagenation from "../../utils/CustomPagenation";

const PostDetail = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const [searchParams] = useSearchParams();
    const pageNum = searchParams.get("page");
    const [page, setPage] = useState({});
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [newReply, setNewReply] = useState("");
    const [replyCommentId, setReplyCommentId] = useState(null);
    const [likeCount, setLikeCount] = useState(0);
    const [liked, setLiked] = useState(false);
    const [showReplies, setShowReplies] = useState({});
    const commentInputRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getDecodedToken();
    const [viewOriginal, setViewOriginal] = useState(true);
    const [posts, setPosts] = useState([]);
    const [listPage, setListPage] = useState(pageNum);


    const handleListPage = (activePage) =>{
        setListPage(activePage);
    }
    useEffect(() => {
        const fetchPostDetail = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/post/${id}`);
                setPost(response.data);
                setLikeCount(response.data.likeCount);
                setLiked(response.data.likedByCurrentUser);

                const commentsResponse = await axios.get(`${apiUrl}/api/post/${id}/comment`);
                setComments(commentsResponse.data);
            } catch (error) {
                console.error("Error fetching post or comments:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchPostDetail();
    }, [id]);

    useEffect(() => {
        if(post && post.category){
            fetchPostList();
        }
    }, [post,listPage])

    const fetchPostList = async () => {
        const response = await axios.get(`/api/post`, {params: {page: listPage -1, category: post.category}})
        setPosts(response.data.content);
        setPage(response.data.page);
    }

    const handleViewOriginal = () => {
        setViewOriginal(!viewOriginal);
    }
    const handleLike = async () => {
        try {
            if (!token) {
                toast(t("PostDetail.LoginToLike"), {icon: '🚫'});
                return;
            }
            const response = await axios.post(`${apiUrl}/api/post/${id}/like`);
            const updatedLiked = !liked;
            setLiked(updatedLiked);
            setLikeCount(likeCount + (updatedLiked ? 1 : -1));

            if (updatedLiked) {
                toast.success(t("PostDetail.LikeSuccess"));
            } else {
                toast.loading(t("PostDetail.LikeRemoved"));
            }
        } catch (error) {
            console.error("Error toggling like:", error);
            toast.error(t("PostDetail.LikeError"));
        }
    };

    const handleInput = (e) => {
        const textarea = commentInputRef.current;
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = `${Math.min(textarea.scrollHeight, 160)}px`;
        }
        setNewComment(e.target.value);
    };

    const handleCommentSubmit = async () => {
        try {
            if (!token) {
                toast(t("PostDetail.LoginToComment"), {icon: '🔒'});
                return;
            }
            const userId = token.id;
            console.log(newComment);
            const response = await axios.post(`${apiUrl}/api/post/${id}/comment`, newComment, {
                headers: {
                    "Content-Type": "text/plain; charset=UTF-8"
                }
            });
            setComments([...comments, response.data]);
            setNewComment("");
            if (commentInputRef.current) {
                commentInputRef.current.style.height = "auto";
            }
        } catch (error) {
            console.error("Error adding comment:", error);
            toast.error(t("PostDetail.CommentError"));
        }
    };

    const handleReplySubmit = async (commentId) => {
        try {
            if (!token) {
                toast(t("PostDetail.LoginToReply"), {icon: '🔒'});
                return;
            }
            const userId = token.id;
            const response = await axios.post(`${apiUrl}/api/post/comments/${commentId}/replies`, newReply, {params: {userId}});
            console.log(response);
            console.log(comments);
            setComments(comments.map(comment =>
                comment.id === commentId
                    ? {...comment, replies: [...(comment.replies || []), response.data]}
                    : comment
            ));
            console.log(comments);
            setNewReply("");
            setReplyCommentId(null);
        } catch (error) {
            console.error("Error adding reply:", error);
            toast.error(t("PostDetail.ReplyError"));
        }
    };

    const toggleReplies = (commentId) => {
        setShowReplies(prevState => ({...prevState, [commentId]: !prevState[commentId]}));
    };

    if (loading) {
        return <div className={styles.loading}>{t("PostDetail.Loading")}</div>;
    }

    if (!post) {
        return <div className={styles.notFound}>{t("PostDetail.PostNotFound")}</div>;
    }

    return (
        <div className={styles.postDetail}>
            <div className={styles.postHeader}>
                <div>
                    {viewOriginal ? <h1 className={styles.title}>{post.title}</h1> :
                        <h1 className={styles.title}>{post.translatedTitle}</h1>}
                    <span style={{fontSize: "14px"}}>{post.userName}</span>
                </div>
                <div className={styles.info}>
                    {/*<button onClick={handleViewOriginal}>{viewOriginal ? t("PostDetail.ViewTranslation") : t("PostDetail.ViewOriginal")} </button>*/}
                    <span>{post.viewCount} {t("PostDetail.ViewCount")}</span>
                    <span>{likeCount} {t("PostDetail.Likes")}</span>
                    <span>{formatDate(post.createdAt)}</span>
                </div>
            </div>

            {viewOriginal ?
                <div className={styles.content} dangerouslySetInnerHTML={{__html: post.content}}/>
                :
                <div className={styles.content} dangerouslySetInnerHTML={{__html: post.translatedContent}}/>
            }


            <div className={styles.likeButtonSection}>
                <button onClick={handleLike} className={`${styles.likeButton} ${liked ? styles.liked : ""}`}>
                    <FaThumbsUp/>
                    {likeCount}
                </button>
            </div>

            <div className={styles.commentSection}>
                <textarea
                    ref={commentInputRef}
                    value={newComment}
                    onChange={handleInput}
                    placeholder={t("PostDetail.CommentPlaceholder")}
                    className={styles.commentInput}
                />
                <button onClick={handleCommentSubmit} className={styles.commentButton}>
                    {t("PostDetail.CommentButton")}
                </button>
            </div>

            <div className={styles.commentList}>
                {comments.length > 0 ? (
                    comments.map((comment) => (
                        <div key={comment.id} className={styles.comment}>
                            <div className={styles.commentHeader}>
                                <span className={styles.commentAuthor}>{comment.authorName}</span>
                                <span className={styles.commentDate}>{formatDate(comment.createdAt)}</span>
                            </div>
                            <p className={styles.commentContent}>{comment.content}</p>
                            <div className={styles.replyToggleButtonBox}>
                                {comment.replies && comment.replies.length > 0 && (
                                    <button onClick={() => toggleReplies(comment.id)}
                                            className={styles.replyToggleButton}>
                                        {showReplies[comment.id] ? t("PostDetail.ReplyToggle") : `${t("PostDetail.ReplyShow")} ${comment.replies.length}`}
                                    </button>
                                )}
                                <button onClick={() => setReplyCommentId(comment.id)}
                                        className={styles.replyToggleButton}>
                                    {t("PostDetail.ReplyAdd")}
                                </button>
                            </div>

                            {replyCommentId === comment.id && (
                                <div className={styles.replySection}>
                                    <textarea
                                        value={newReply}
                                        onChange={(e) => setNewReply(e.target.value)}
                                        placeholder={t("PostDetail.ReplyPlaceholder")}
                                        className={styles.replyInput}
                                    />
                                    <div className={styles.replyButtonContainer}>
                                        <button onClick={() => handleReplySubmit(comment.id)}
                                                className={styles.replyButtonSmall}>
                                            {t("PostDetail.ReplyButton")}
                                        </button>
                                        <button onClick={() => setReplyCommentId(null)}
                                                className={styles.cancelReplyButton}>
                                            {t("PostDetail.CancelReplyButton")}
                                        </button>
                                    </div>
                                </div>
                            )}

                            {showReplies[comment.id] && comment.replies && comment.replies.map((reply) => (
                                <div key={reply.id} className={styles.reply}>
                                    <div className={styles.commentHeader}>
                                        <span className={styles.replyAuthor}>{reply.authorName}</span>
                                        <span className={styles.replyDate}>{formatDate(reply.createdAt)}</span>
                                    </div>
                                    <p className={styles.replyContent}>{reply.content}</p>
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <div className={styles.noComments}>{t("PostDetail.NoComments")}</div>
                )}
            </div>
            <div className={styles.postList}>
                <h4>{t("postList.title")}</h4>
                <PostList posts={posts} id={post.id}/>
                <CustomPagenation page={page} handleActivePage={handleListPage}/>
            </div>

        </div>
    );
};

export default PostDetail;
