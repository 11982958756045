import React, {useState} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./ContactForm.css";
import {useTranslation} from 'react-i18next';
import axios from "../../utils/axios";
import {getDecodedToken} from "../../utils/jwtUtils";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        inquiryContent: "",
        country: "", // 국가 추가
        modelId: ""
    });
    const {t} = useTranslation();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            phone: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.post(`api/contact`, formData)
            .then(() => {
                alert("문의 성공");
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    inquiryContent: "",
                    country: "", // 국가 추가
                    modelId: ""
                });
            });
    };

    return (
        <div className="contact-container" style={{marginTop: "50px"}}>
            <h1>{t('contactForm.title')}</h1>
            <div>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">{t('contactForm.name')}</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">{t('contactForm.email')}</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inquiryContent">{t('contactForm.inquiryContent')}</label>
                        <textarea
                            id="inquiryContent"
                            name="inquiryContent"
                            value={formData.inquiryContent}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit">{t('contactForm.submit')}</button>
                    </div>
                </form>
            </div>
        </div>


    );
};

export default ContactForm;
