import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import "./Gallery.css";
import CustomPagenation from "../../utils/CustomPagenation";
import axios from "../../utils/axios";
import Carousel from "react-bootstrap/Carousel";
import {useLocation} from "react-router-dom";
const Gallery = () => {
    const videoIds = [
        "0Wam6dGjjZI", // 첫 번째 영상
        "ftpg0N9LW-0", // 두 번째 영상
        "YSOjukj6R24"  // 세 번째 영상
    ];
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentPage , setCurrentPage] = useState(1);
    const [page, setPage] = useState({});
    const [images, setImages] = useState([]);
    const playerRefs = useRef([]); // 유튜브 플레이어 객체 저장
    const [apiReady, setApiReady] = useState(false); // API 로딩 상태
    const [activeIndex, setActiveIndex] = useState(0); // 현재 캐러셀 위치
    const location =  useLocation(); // 현재 페이지(라우터) 감지

    const videoRefs = useRef([]);

    const stopAllVideos = () => {
        videoRefs.current.forEach(video => {
            if (video) {
                video.pause();
                video.currentTime = 0; // 처음으로 되감기
            }
        });
    };

    useEffect(() => {
        // 🔹 YouTube API가 로드되지 않았다면 추가
        if (!window.YT) {
            const script = document.createElement("script");
            script.src = "https://www.youtube.com/iframe_api";
            script.async = true;
            script.onload = () => {
                // console.log("✅ YouTube API Loaded");
            };
            document.body.appendChild(script);
        }

        // 🔹 API가 준비되면 플레이어 초기화
        window.onYouTubeIframeAPIReady = () => {
            // console.log("✅ YouTube API Ready!");
            setApiReady(true);
        };

        // 🔹 페이지 변경 감지 후 API 다시 확인
        return () => {
            // console.log("🔄 Page changed, checking YouTube API...");
            if (window.YT && window.YT.Player) {
                setApiReady(true);
            }
        };
    }, [location]); // 🔥 라우터 변경 시마다 실행

    // 🔹 API가 로드된 후에만 플레이어 생성
    useEffect(() => {
        if (apiReady) {
            // console.log("🎬 Initializing YouTube Players...");
            playerRefs.current = videoIds.map((videoId, index) =>
                new window.YT.Player(`youtube-player-${index}`, {
                    videoId: videoId,
                    events: {
                        'onReady': (event) => console.log(`✅ Player ${index} Ready`),
                    }
                })
            );
            // console.log("✅ All Players Initialized:", playerRefs.current);
        }
    }, [apiReady]);


    const handleSelect = (selectedIndex) => {
        // console.log(`🔄 Changing to slide ${selectedIndex}`);
        setActiveIndex(selectedIndex);

        // 안전한 플레이어 정지 체크
        playerRefs.current.forEach((player, index) => {
            if (player && typeof player.pauseVideo === "function" && index !== selectedIndex) {
                // console.log(`⏸️ Pausing video ${index}`);
                player.pauseVideo();
            } else {
                console.warn(`⚠️ Player ${index} is not ready or doesn't support API control.`);
            }
        });
    };

    useEffect(() =>{
        fetchImage();
    }, [currentPage])

    const handleActivePage = (page) =>{
        setCurrentPage(page);
    }
    const fetchImage = async () => {
        const response = await axios.get("/api/gallery/images",{
            params : {
                size : 12,
                page : currentPage -1
            }
        })
        setPage(response.data.page);
        setImages(response.data.content);

    }

    return (
        <div className="gallery-container">
            <h2 className="gallery-title">{t("gallery.title")}</h2>
            <p className="gallery-description">{t("gallery.description")}</p>

            <Carousel controls={true} indicators={true} interval={null} onSlide={stopAllVideos} >
                {[7,8,9,10,11].map((num, index) => (
                    <Carousel.Item key={index}>
                        <video
                            ref={el => videoRefs.current[index] = el}
                            src={`https://d1l0rqr6f7o97b.cloudfront.net/jjmotors_${num}.mp4`}
                            controls
                        ></video>
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className="gallery-grid">
                {images.map((img, index) => (
                    <img
                        key={index}
                        src={img.imageUrl}
                        alt={`${t("gallery.imageAlt")} ${index + 1}`}
                        className="gallery-item"
                        onClick={() => setSelectedImage(img.imageUrl)}
                    />
                ))}
            </div>

            {selectedImage && (
                <div className="gallery-modal" onClick={() => setSelectedImage(null)}>
                    <img src={selectedImage} alt={t("gallery.selectedImage")} className="modal-image" />
                </div>
            )}

            <CustomPagenation page={page} handleActivePage={handleActivePage}></CustomPagenation>
        </div>
    );
};

export default Gallery;
