export const countries = [
    {
        "value": "AFG",
        "label": "Afghanistan (افغانستان)",
        "flag": "AF",
        "continent": "Asia"
    },
    {
        "value": "ALB",
        "label": "Albania (Shqipëri)",
        "flag": "AL",
        "continent": "Europe"
    },
    {
        "value": "DZA",
        "label": "Algeria (الجزائر)",
        "flag": "DZ",
        "continent": "Africa"
    },
    {
        "value": "ARG",
        "label": "Argentina (Argentina)",
        "flag": "AR",
        "continent": "South America"
    },
    {
        "value": "AUS",
        "label": "Australia (Australia)",
        "flag": "AU",
        "continent": "Oceania"
    },
    {
        "value": "AUT",
        "label": "Austria (Österreich)",
        "flag": "AT",
        "continent": "Europe"
    },
    {
        "value": "AZE",
        "label": "Azerbaijan (Azərbaycan)",
        "flag": "AZ",
        "continent": "Asia"
    },
    {
        "value": "BHR",
        "label": "Bahrain (البحرين)",
        "flag": "BH",
        "continent": "Asia"
    },
    {
        "value": "BGD",
        "label": "Bangladesh (বাংলাদেশ)",
        "flag": "BD",
        "continent": "Asia"
    },
    {
        "value": "BLR",
        "label": "Belarus (Беларусь)",
        "flag": "BY",
        "continent": "Europe"
    },
    {
        "value": "BEL",
        "label": "Belgium (België)",
        "flag": "BE",
        "continent": "Europe"
    },
    {
        "value": "BLZ",
        "label": "Belize (Belize)",
        "flag": "BZ",
        "continent": "North America"
    },
    {
        "value": "BEN",
        "label": "Benin (Bénin)",
        "flag": "BJ",
        "continent": "Africa"
    },
    {
        "value": "BTN",
        "label": "Bhutan (འབྲུག)",
        "flag": "BT",
        "continent": "Asia"
    },
    {
        "value": "BOL",
        "label": "Bolivia (Bolivia)",
        "flag": "BO",
        "continent": "South America"
    },
    {
        "value": "BRA",
        "label": "Brazil (Brasil)",
        "flag": "BR",
        "continent": "South America"
    },
    {
        "value": "BRN",
        "label": "Brunei (بروني)",
        "flag": "BN",
        "continent": "Asia"
    },
    {
        "value": "BGR",
        "label": "Bulgaria (България)",
        "flag": "BG",
        "continent": "Europe"
    },
    {
        "value": "BFA",
        "label": "Burkina Faso (Burkina Faso)",
        "flag": "BF",
        "continent": "Africa"
    },
    {
        "value": "BDI",
        "label": "Burundi (Burundi)",
        "flag": "BI",
        "continent": "Africa"
    },
    {
        "value": "KHM",
        "label": "Cambodia (កម្ពុជា)",
        "flag": "KH",
        "continent": "Asia"
    },
    {
        "value": "CMR",
        "label": "Cameroon (Cameroun)",
        "flag": "CM",
        "continent": "Africa"
    },
    {
        "value": "CAN",
        "label": "Canada (Canada)",
        "flag": "CA",
        "continent": "North America"
    },
    {
        "value": "CPV",
        "label": "Cape Verde (Cabo Verde)",
        "flag": "CV",
        "continent": "Africa"
    },
    {
        "value": "CAF",
        "label": "Central African Republic (République centrafricaine)",
        "flag": "CF",
        "continent": "Africa"
    },
    {
        "value": "TCD",
        "label": "Chad (تشاد)",
        "flag": "TD",
        "continent": "Africa"
    },
    {
        "value": "CHL",
        "label": "Chile (Chile)",
        "flag": "CL",
        "continent": "South America"
    },
    {
        "value": "CHN",
        "label": "China (中国)",
        "flag": "CN",
        "continent": "Asia"
    },
    {
        "value": "COL",
        "label": "Colombia (Colombia)",
        "flag": "CO",
        "continent": "South America"
    },
    {
        "value": "COM",
        "label": "Comoros (Komori)",
        "flag": "KM",
        "continent": "Africa"
    },
    {
        "value": "COG",
        "label": "Congo (République du Congo)",
        "flag": "CG",
        "continent": "Africa"
    },
    {
        "value": "COD",
        "label": "Congo (Democratic Republic)",
        "flag": "CD",
        "continent": "Africa"
    },
    {
        "value": "CRI",
        "label": "Costa Rica (Costa Rica)",
        "flag": "CR",
        "continent": "North America"
    },
    {
        "value": "CIV",
        "label": "Côte d'Ivoire (Côte d'Ivoire)",
        "flag": "CI",
        "continent": "Africa"
    },
    {
        "value": "HRV",
        "label": "Croatia (Hrvatska)",
        "flag": "HR",
        "continent": "Europe"
    },
    {
        "value": "CUB",
        "label": "Cuba (Cuba)",
        "flag": "CU",
        "continent": "North America"
    },
    {
        "value": "CYP",
        "label": "Cyprus (Κύπρος)",
        "flag": "CY",
        "continent": "Asia"
    },
    {
        "value": "CZE",
        "label": "Czech Republic (Česko)",
        "flag": "CZ",
        "continent": "Europe"
    },
    {
        "value": "DNK",
        "label": "Denmark (Danmark)",
        "flag": "DK",
        "continent": "Europe"
    },
    {
        "value": "DJI",
        "label": "Djibouti (Djibouti)",
        "flag": "DJ",
        "continent": "Africa"
    },
    {
        "value": "DMA",
        "label": "Dominica (Dominica)",
        "flag": "DM",
        "continent": "North America"
    },
    {
        "value": "DOM",
        "label": "Dominican Republic (República Dominicana)",
        "flag": "DO",
        "continent": "North America"
    },
    {
        "value": "ECU",
        "label": "Ecuador (Ecuador)",
        "flag": "EC",
        "continent": "South America"
    },
    {
        "value": "EGY",
        "label": "Egypt (مصر)",
        "flag": "EG",
        "continent": "Africa"
    },
    {
        "value": "SLV",
        "label": "El Salvador (El Salvador)",
        "flag": "SV",
        "continent": "North America"
    },
    {
        "value": "GNQ",
        "label": "Equatorial Guinea (Guinea Ecuatorial)",
        "flag": "GQ",
        "continent": "Africa"
    },
    {
        "value": "ERI",
        "label": "Eritrea (Eritrea)",
        "flag": "ER",
        "continent": "Africa"
    },
    {
        "value": "EST",
        "label": "Estonia (Eesti)",
        "flag": "EE",
        "continent": "Europe"
    },
    {
        "value": "ETH",
        "label": "Ethiopia (Ethiopia)",
        "flag": "ET",
        "continent": "Africa"
    },
    {
        "value": "FJI",
        "label": "Fiji (Fiji)",
        "flag": "FJ",
        "continent": "Oceania"
    },
    {
        "value": "FIN",
        "label": "Finland (Suomi)",
        "flag": "FI",
        "continent": "Europe"
    },
    {
        "value": "FRA",
        "label": "France (France)",
        "flag": "FR",
        "continent": "Europe"
    },
    {
        "value": "GAB",
        "label": "Gabon (Gabon)",
        "flag": "GA",
        "continent": "Africa"
    },
    {
        "value": "GMB",
        "label": "Gambia (Gambia)",
        "flag": "GM",
        "continent": "Africa"
    },
    {
        "value": "GEO",
        "label": "Georgia (საქართველო)",
        "flag": "GE",
        "continent": "Asia"
    },
    {
        "value": "DEU",
        "label": "Germany (Deutschland)",
        "flag": "DE",
        "continent": "Europe"
    },
    {
        "value": "GHA",
        "label": "Ghana (Ghana)",
        "flag": "GH",
        "continent": "Africa"
    },
    {
        "value": "GRC",
        "label": "Greece (Ελλάδα)",
        "flag": "GR",
        "continent": "Europe"
    },
    {
        "value": "GTM",
        "label": "Guatemala (Guatemala)",
        "flag": "GT",
        "continent": "North America"
    },
    {
        "value": "GIN",
        "label": "Guinea (Guinée)",
        "flag": "GN",
        "continent": "Africa"
    },
    {
        "value": "GNB",
        "label": "Guinea-Bissau (Guiné-Bissau)",
        "flag": "GW",
        "continent": "Africa"
    },
    {
        "value": "GUY",
        "label": "Guyana (Guyana)",
        "flag": "GY",
        "continent": "South America"
    },
    {
        "value": "HTI",
        "label": "Haiti (Haïti)",
        "flag": "HT",
        "continent": "North America"
    },
    {
        "value": "HND",
        "label": "Honduras (Honduras)",
        "flag": "HN",
        "continent": "North America"
    },
    {
        "value": "HUN",
        "label": "Hungary (Magyarország)",
        "flag": "HU",
        "continent": "Europe"
    },
    {
        "value": "ISL",
        "label": "Iceland (Ísland)",
        "flag": "IS",
        "continent": "Europe"
    },
    {
        "value": "IND",
        "label": "India (भारत)",
        "flag": "IN",
        "continent": "Asia"
    },
    {
        "value": "IDN",
        "label": "Indonesia (Indonesia)",
        "flag": "ID",
        "continent": "Asia"
    },
    {
        "value": "IRN",
        "label": "Iran (ایران)",
        "flag": "IR",
        "continent": "Asia"
    },
    {
        "value": "IRQ",
        "label": "Iraq (العراق)",
        "flag": "IQ",
        "continent": "Asia"
    },
    {
        "value": "IRL",
        "label": "Ireland (Éire)",
        "flag": "IE",
        "continent": "Europe"
    },
    {
        "value": "ISR",
        "label": "Israel (ישראל)",
        "flag": "IL",
        "continent": "Asia"
    },
    {
        "value": "ITA",
        "label": "Italy (Italia)",
        "flag": "IT",
        "continent": "Europe"
    },
    {
        "value": "JAM",
        "label": "Jamaica (Jamaica)",
        "flag": "JM",
        "continent": "North America"
    },
    {
        "value": "JPN",
        "label": "Japan (日本)",
        "flag": "JP",
        "continent": "Asia"
    },
    {
        "value": "JOR",
        "label": "Jordan (الأردن)",
        "flag": "JO",
        "continent": "Asia"
    },
    {
        "value": "KAZ",
        "label": "Kazakhstan (Қазақстан)",
        "flag": "KZ",
        "continent": "Asia"
    },
    {
        "value": "KEN",
        "label": "Kenya (Kenya)",
        "flag": "KE",
        "continent": "Africa"
    },
    {
        "value": "KIR",
        "label": "Kiribati (Kiribati)",
        "flag": "KI",
        "continent": "Oceania"
    },
    {
        "value": "PRK",
        "label": "North Korea (북한)",
        "flag": "KP",
        "continent": "Asia"
    },
    {
        "value": "KOR",
        "label": "South Korea (대한민국)",
        "flag": "KR",
        "continent": "Asia"
    },
    {
        "value": "KWT",
        "label": "Kuwait (الكويت)",
        "flag": "KW",
        "continent": "Asia"
    },
    {
        "value": "KGZ",
        "label": "Kyrgyzstan (Кыргызстан)",
        "flag": "KG",
        "continent": "Asia"
    },
    {
        "value": "LAO",
        "label": "Laos (ລາວ)",
        "flag": "LA",
        "continent": "Asia"
    },
    {
        "value": "LVA",
        "label": "Latvia (Latvija)",
        "flag": "LV",
        "continent": "Europe"
    },
    {
        "value": "LBN",
        "label": "Lebanon (لبنان)",
        "flag": "LB",
        "continent": "Asia"
    },
    {
        "value": "LSO",
        "label": "Lesotho (Lesotho)",
        "flag": "LS",
        "continent": "Africa"
    },
    {
        "value": "LBR",
        "label": "Liberia (Liberia)",
        "flag": "LR",
        "continent": "Africa"
    },
    {
        "value": "LBY",
        "label": "Libya (ليبيا)",
        "flag": "LY",
        "continent": "Africa"
    },
    {
        "value": "LIE",
        "label": "Liechtenstein (Liechtenstein)",
        "flag": "LI",
        "continent": "Europe"
    },
    {
        "value": "LTU",
        "label": "Lithuania (Lietuva)",
        "flag": "LT",
        "continent": "Europe"
    },
    {
        "value": "LUX",
        "label": "Luxembourg (Luxembourg)",
        "flag": "LU",
        "continent": "Europe"
    },
    {
        "value": "MDG",
        "label": "Madagascar (Madagasikara)",
        "flag": "MG",
        "continent": "Africa"
    },
    {
        "value": "MWI",
        "label": "Malawi (Malawi)",
        "flag": "MW",
        "continent": "Africa"
    },
    {
        "value": "MYS",
        "label": "Malaysia (Malaysia)",
        "flag": "MY",
        "continent": "Asia"
    },
    {
        "value": "MDV",
        "label": "Maldives (Maldives)",
        "flag": "MV",
        "continent": "Asia"
    },
    {
        "value": "MLI",
        "label": "Mali (Mali)",
        "flag": "ML",
        "continent": "Africa"
    },
    {
        "value": "MLT",
        "label": "Malta (Malta)",
        "flag": "MT",
        "continent": "Europe"
    },
    {
        "value": "MHL",
        "label": "Marshall Islands (Marshall Islands)",
        "flag": "MH",
        "continent": "Oceania"
    },
    {
        "value": "MRT",
        "label": "Mauritania (موريتانيا)",
        "flag": "MR",
        "continent": "Africa"
    },
    {
        "value": "MUS",
        "label": "Mauritius (Mauritius)",
        "flag": "MU",
        "continent": "Africa"
    },
    {
        "value": "MEX",
        "label": "Mexico (México)",
        "flag": "MX",
        "continent": "North America"
    },
    {
        "value": "FSM",
        "label": "Micronesia (Micronesia)",
        "flag": "FM",
        "continent": "Oceania"
    },
    {
        "value": "MDA",
        "label": "Moldova (Republica Moldova)",
        "flag": "MD",
        "continent": "Europe"
    },
    {
        "value": "MCO",
        "label": "Monaco (Monaco)",
        "flag": "MC",
        "continent": "Europe"
    },
    {
        "value": "MNG",
        "label": "Mongolia (Монгол)",
        "flag": "MN",
        "continent": "Asia"
    },
    {
        "value": "MNE",
        "label": "Montenegro (Crna Gora)",
        "flag": "ME",
        "continent": "Europe"
    },
    {
        "value": "MAR",
        "label": "Morocco (المغرب)",
        "flag": "MA",
        "continent": "Africa"
    },
    {
        "value": "MOZ",
        "label": "Mozambique (Moçambique)",
        "flag": "MZ",
        "continent": "Africa"
    },
    {
        "value": "MMR",
        "label": "Myanmar (Burma) (မြန်မာ)",
        "flag": "MM",
        "continent": "Asia"
    },
    {
        "value": "NAM",
        "label": "Namibia (Namibia)",
        "flag": "NA",
        "continent": "Africa"
    },
    {
        "value": "NRU",
        "label": "Nauru (Nauru)",
        "flag": "NR",
        "continent": "Oceania"
    },
    {
        "value": "NPL",
        "label": "Nepal (नेपाल)",
        "flag": "NP",
        "continent": "Asia"
    },
    {
        "value": "NLD",
        "label": "Netherlands (Nederland)",
        "flag": "NL",
        "continent": "Europe"
    },
    {
        "value": "NZL",
        "label": "New Zealand (New Zealand)",
        "flag": "NZ",
        "continent": "Oceania"
    },
    {
        "value": "NIC",
        "label": "Nicaragua (Nicaragua)",
        "flag": "NI",
        "continent": "North America"
    },
    {
        "value": "NER",
        "label": "Niger (Niger)",
        "flag": "NE",
        "continent": "Africa"
    },
    {
        "value": "NGA",
        "label": "Nigeria (Nigeria)",
        "flag": "NG",
        "continent": "Africa"
    },
    {
        "value": "NOR",
        "label": "Norway (Norge)",
        "flag": "NO",
        "continent": "Europe"
    },
    {
        "value": "OMN",
        "label": "Oman (عمان)",
        "flag": "OM",
        "continent": "Asia"
    },
    {
        "value": "PAK",
        "label": "Pakistan (پاکستان)",
        "flag": "PK",
        "continent": "Asia"
    },
    {
        "value": "PLW",
        "label": "Palau (Palau)",
        "flag": "PW",
        "continent": "Oceania"
    },
    {
        "value": "PAN",
        "label": "Panama (Panamá)",
        "flag": "PA",
        "continent": "North America"
    },
    {
        "value": "PNG",
        "label": "Papua New Guinea (Papua New Guinea)",
        "flag": "PG",
        "continent": "Oceania"
    },
    {
        "value": "PRY",
        "label": "Paraguay (Paraguay)",
        "flag": "PY",
        "continent": "South America"
    },
    {
        "value": "PER",
        "label": "Peru (Perú)",
        "flag": "PE",
        "continent": "South America"
    },
    {
        "value": "PHL",
        "label": "Philippines (Pilipinas)",
        "flag": "PH",
        "continent": "Asia"
    },
    {
        "value": "POL",
        "label": "Poland (Polska)",
        "flag": "PL",
        "continent": "Europe"
    },
    {
        "value": "PRT",
        "label": "Portugal (Portugal)",
        "flag": "PT",
        "continent": "Europe"
    },
    {
        "value": "QAT",
        "label": "Qatar (قطر)",
        "flag": "QA",
        "continent": "Asia"
    },
    {
        "value": "ROU",
        "label": "Romania (România)",
        "flag": "RO",
        "continent": "Europe"
    },
    {
        "value": "RUS",
        "label": "Russia (Россия)",
        "flag": "RU",
        "continent": "Europe"
    },
    {
        "value": "RWA",
        "label": "Rwanda (Rwanda)",
        "flag": "RW",
        "continent": "Africa"
    },
    {
        "value": "KNA",
        "label": "Saint Kitts and Nevis (Saint Kitts and Nevis)",
        "flag": "KN",
        "continent": "North America"
    },
    {
        "value": "LCA",
        "label": "Saint Lucia (Saint Lucia)",
        "flag": "LC",
        "continent": "North America"
    },
    {
        "value": "VCT",
        "label": "Saint Vincent and the Grenadines (Saint Vincent and the Grenadines)",
        "flag": "VC",
        "continent": "North America"
    },
    {
        "value": "WSM",
        "label": "Samoa (Samoa)",
        "flag": "WS",
        "continent": "Oceania"
    },
    {
        "value": "SMR",
        "label": "San Marino (San Marino)",
        "flag": "SM",
        "continent": "Europe"
    },
    {
        "value": "STP",
        "label": "São Tomé and Príncipe (São Tomé and Príncipe)",
        "flag": "ST",
        "continent": "Africa"
    },
    {
        "value": "SAU",
        "label": "Saudi Arabia (المملكة العربية السعودية)",
        "flag": "SA",
        "continent": "Asia"
    },
    {
        "value": "SEN",
        "label": "Senegal (Sénégal)",
        "flag": "SN",
        "continent": "Africa"
    },
    {
        "value": "SRB",
        "label": "Serbia (Србија)",
        "flag": "RS",
        "continent": "Europe"
    },
    {
        "value": "SYC",
        "label": "Seychelles (Seychelles)",
        "flag": "SC",
        "continent": "Africa"
    },
    {
        "value": "SLE",
        "label": "Sierra Leone (Sierra Leone)",
        "flag": "SL",
        "continent": "Africa"
    },
    {
        "value": "SGP",
        "label": "Singapore (新加坡)",
        "flag": "SG",
        "continent": "Asia"
    },
    {
        "value": "SVK",
        "label": "Slovakia (Slovensko)",
        "flag": "SK",
        "continent": "Europe"
    },
    {
        "value": "SVN",
        "label": "Slovenia (Slovenija)",
        "flag": "SI",
        "continent": "Europe"
    },
    {
        "value": "SLB",
        "label": "Solomon Islands (Solomon Islands)",
        "flag": "SB",
        "continent": "Oceania"
    },
    {
        "value": "SOM",
        "label": "Somalia (Soomaaliya)",
        "flag": "SO",
        "continent": "Africa"
    },
    {
        "value": "ZAF",
        "label": "South Africa (South Africa)",
        "flag": "ZA",
        "continent": "Africa"
    },
    {
        "value": "ESP",
        "label": "Spain (España)",
        "flag": "ES",
        "continent": "Europe"
    },
    {
        "value": "LKA",
        "label": "Sri Lanka (ශ්‍රී ලංකාව)",
        "flag": "LK",
        "continent": "Asia"
    },
    {
        "value": "SDN",
        "label": "Sudan (السودان)",
        "flag": "SD",
        "continent": "Africa"
    },
    {
        "value": "SUR",
        "label": "Suriname (Suriname)",
        "flag": "SR",
        "continent": "South America"
    },
    {
        "value": "SWZ",
        "label": "Eswatini (Swaziland)",
        "flag": "SZ",
        "continent": "Africa"
    },
    {
        "value": "SWE",
        "label": "Sweden (Sverige)",
        "flag": "SE",
        "continent": "Europe"
    },
    {
        "value": "CHE",
        "label": "Switzerland (Schweiz)",
        "flag": "CH",
        "continent": "Europe"
    },
    {
        "value": "SYR",
        "label": "Syria (سوريا)",
        "flag": "SY",
        "continent": "Asia"
    },
    {
        "value": "TWN",
        "label": "Taiwan (台灣)",
        "flag": "TW",
        "continent": "Asia"
    },
    {
        "value": "TJK",
        "label": "Tajikistan (Тоҷикистон)",
        "flag": "TJ",
        "continent": "Asia"
    },
    {
        "value": "TZA",
        "label": "Tanzania (Tanzania)",
        "flag": "TZ",
        "continent": "Africa"
    },
    {
        "value": "THA",
        "label": "Thailand (ประเทศไทย)",
        "flag": "TH",
        "continent": "Asia"
    },
    {
        "value": "TLS",
        "label": "Timor-Leste (Timor-Leste)",
        "flag": "TL",
        "continent": "Asia"
    },
    {
        "value": "TGO",
        "label": "Togo (Togo)",
        "flag": "TG",
        "continent": "Africa"
    },
    {
        "value": "TON",
        "label": "Tonga (Tonga)",
        "flag": "TO",
        "continent": "Oceania"
    },
    {
        "value": "TTO",
        "label": "Trinidad and Tobago (Trinidad and Tobago)",
        "flag": "TT",
        "continent": "North America"
    },
    {
        "value": "TUN",
        "label": "Tunisia (تونس)",
        "flag": "TN",
        "continent": "Africa"
    },
    {
        "value": "TUR",
        "label": "Turkey (Türkiye)",
        "flag": "TR",
        "continent": "Asia"
    },
    {
        "value": "TKM",
        "label": "Turkmenistan (Türkmenistan)",
        "flag": "TM",
        "continent": "Asia"
    },
    {
        "value": "TUV",
        "label": "Tuvalu (Tuvalu)",
        "flag": "TV",
        "continent": "Oceania"
    },
    {
        "value": "UGA",
        "label": "Uganda (Uganda)",
        "flag": "UG",
        "continent": "Africa"
    },
    {
        "value": "UKR",
        "label": "Ukraine (Україна)",
        "flag": "UA",
        "continent": "Europe"
    },
    {
        "value": "ARE",
        "label": "United Arab Emirates (الإمارات)",
        "flag": "AE",
        "continent": "Asia"
    },
    {
        "value": "GBR",
        "label": "United Kingdom (United Kingdom)",
        "flag": "GB",
        "continent": "Europe"
    },
    {
        "value": "USA",
        "label": "United States (United States)",
        "flag": "US",
        "continent": "North America"
    },
    {
        "value": "URY",
        "label": "Uruguay (Uruguay)",
        "flag": "UY",
        "continent": "South America"
    },
    {
        "value": "UZB",
        "label": "Uzbekistan (Oʻzbekiston)",
        "flag": "UZ",
        "continent": "Asia"
    },
    {
        "value": "VUT",
        "label": "Vanuatu (Vanuatu)",
        "flag": "VU",
        "continent": "Oceania"
    },
    {
        "value": "VEN",
        "label": "Venezuela (Venezuela)",
        "flag": "VE",
        "continent": "South America"
    },
    {
        "value": "VNM",
        "label": "Vietnam (Việt Nam)",
        "flag": "VN",
        "continent": "Asia"
    },
    {
        "value": "YEM",
        "label": "Yemen (اليمن)",
        "flag": "YE",
        "continent": "Asia"
    },
    {
        "value": "ZMB",
        "label": "Zambia (Zambia)",
        "flag": "ZM",
        "continent": "Africa"
    },
    {
        "value": "ZWE",
        "label": "Zimbabwe (Zimbabwe)",
        "flag": "ZW",
        "continent": "Africa"
    }
];