import React from 'react';
import styles from "./BannerManagement.module.css"
import {Container, Tabs, Tab, Table, Modal, Form, Button} from "react-bootstrap";
import axios from "../../../utils/axios";
import {useState, useEffect} from "react";
const BannerManagement = () => {

    // 배너 목록
    const [banners, setBanners] = useState([]);
    const [selectedBannerType, setSelectedBannerType] = useState("Main");
    const [bannerTabs, setBannerTabs] = useState([
        {key : "Main", title: "Main"},
        {key: "LSied", title : "Left-Side"},
        {key: "RSide", title: "Right-Side"}
    ])

    // 배너 등록/수정용 폼 상태
    const [formData, setFormData] = useState({
        id: null,
        title: "",
        linkUrl: "",
        description: "",
        bannerType: "MAIN", // 기본값
        isActive: true,
        displayOrder: 0,
        startDate: "",
        endDate: ""
        // imageUrl 필드는 따로 두지 않고, 서버 측에서 생성된 URL을 저장하는 로직으로 바꿀 수도 있음
    });

    // 업로드할 이미지 파일 상태
    const [imageFile, setImageFile] = useState(null);

    // 등록/수정 모달 제어
    const [showFormModal, setShowFormModal] = useState(false);

    // 삭제 확인 모달 제어
    const [deleteTarget, setDeleteTarget] = useState(null);

    // 컴포넌트 마운트 시 전체 배너 목록 불러오기
    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await axios.get("/api/banners");
            setBanners(response.data);
        } catch (error) {
            console.error("배너 목록을 가져오는 중 오류 발생:", error);
        }
    };

    const handleSelectedBannerType = (bannerType) => {
        console.log(bannerType);
        setSelectedBannerType(bannerType);
    }

    // 폼 열기 (새 배너 등록)
    const handleOpenCreateForm = () => {
        setFormData({
            id: null,
            title: "",
            linkUrl: "",
            description: "",
            bannerType: selectedBannerType,
            isActive: true,
            displayOrder: 0,
            startDate: "",
            endDate: ""
        });
        setImageFile(null);
        setShowFormModal(true);
    };

    // 폼 열기 (배너 수정)
    const handleOpenEditForm = (banner) => {
        // 백엔드에서 startDate/endDate가 "2025-03-01T10:00:00" 형식으로 넘어온다고 가정
        // 또는 "2025-03-01 10:00:00"처럼 포맷이 다를 경우 파싱 로직이 필요할 수 있음
        setFormData({
            id: banner.id,
            title: banner.title || "",
            linkUrl: banner.linkUrl || "",
            description: banner.description || "",
            bannerType: selectedBannerType || "MAIN",
            isActive: banner.isActive,
            displayOrder: banner.displayOrder || 0,
            startDate: banner.startDate
                ? banner.startDate.substring(0, 16) // "YYYY-MM-DDTHH:MM"만 사용
                : "",
            endDate: banner.endDate
                ? banner.endDate.substring(0, 16)
                : ""
        });
        // 이미지는 수정 시 기존 경로가 있더라도 새로 업로드할 수 있도록 별도 관리
        setImageFile(null);
        setShowFormModal(true);
    };

    // 배너 삭제 모달 열기
    const handleOpenDeleteModal = (banner) => {
        setDeleteTarget(banner);
    };

    // 폼에서 각 필드 값 변경
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        // isActive(checkbox)인 경우 처리
        if (type === "checkbox") {
            setFormData({ ...formData, [name]: checked });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    // 이미지 파일 변경 시 처리
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setImageFile(e.target.files[0]);
        } else {
            setImageFile(null);
        }
    };

    // 배너 등록/수정 처리
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // 멀티파트 전송을 위한 FormData 생성
            const multipartData = new FormData();
            const dto = {
                title : formData.title,
                linkUrl : formData.linkUrl,
                description : formData.description,
                bannerType : formData.bannerType,
                isActive : formData.isActive,
                displayOrder : formData.displayOrder,
                startDate : formData.startDate,
                endDate : formData.endDate,
            }

            // formData에 들어있는 문자열/숫자/boolean 필드들을 append

            multipartData.append("bannerDto", new Blob([JSON.stringify(dto)], { type: "application/json" }));
            if (imageFile) {
                // 'imageFile'이라는 필드명으로 파일 자체를 전송
                multipartData.append("imageFile", imageFile);
            }

            if (formData.id === null) {
                // 새 배너 등록
                await axios.post("/api/banners", multipartData, {
                    headers: { "Content-Type": "multipart/form-data" }
                });
            } else {
                // 기존 배너 수정
                // PUT 메서드로 multipart를 보낼 때, 서버 설정에 따라 PATCH를 쓰거나 POST를 쓰는 경우도 있으니 주의
                await axios.put(`/api/banners/${formData.id}`, multipartData, {
                    headers: { "Content-Type": "multipart/form-data" }
                });
            }

            setShowFormModal(false);
            fetchBanners(); // 목록 갱신
        } catch (error) {
            console.error("배너 저장 중 오류 발생:", error);
        }
    };

    // 배너 삭제 확정
    const handleDelete = async () => {
        if (!deleteTarget) return;

        try {
            await axios.delete(`/api/banners/${deleteTarget.id}`);
            setDeleteTarget(null);
            fetchBanners();
        } catch (error) {
            console.error("배너 삭제 중 오류 발생:", error);
        }
    };

    return (
        <Container className="mt-5">
            <h1>배너 관리</h1>

            {/* 새 배너 등록 버튼 */}
            <div className="mb-3">
                <Button variant="primary" onClick={handleOpenCreateForm}>
                    새 배너 등록
                </Button>
            </div>

            <Tabs
                activeKey={selectedBannerType}
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(k) => handleSelectedBannerType(k)}
            >

                {
                    bannerTabs.map(t => {
                        return <Tab eventKey={t.key} title={t.title}>
                            <Table bordered hover>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>제목</th>
                                    <th>타입</th>
                                    <th>링크</th>
                                    <th>노출 여부</th>
                                    <th>정렬 순서</th>
                                    <th>시작일</th>
                                    <th>종료일</th>
                                    <th>관리</th>
                                </tr>
                                </thead>
                                <tbody>
                                {banners.length > 0 ? banners.map((banner) => (
                                    <tr key={banner.id}>
                                        <td>{banner.id}</td>
                                        <td>{banner.title}</td>
                                        <td>{banner.bannerType}</td>
                                        <td>
                                            <a href={banner.linkUrl} target="_blank" rel="noopener noreferrer">
                                                이동
                                            </a>
                                        </td>
                                        <td>{banner.isActive ? "활성" : "비활성"}</td>
                                        <td>{banner.displayOrder}</td>
                                        <td>
                                            {banner.startDate
                                                ? banner.startDate.substring(0, 16).replace("T", " ")
                                                : ""}
                                        </td>
                                        <td>
                                            {banner.endDate
                                                ? banner.endDate.substring(0, 16).replace("T", " ")
                                                : ""}
                                        </td>
                                        <td>
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                className="me-1"
                                                onClick={() => handleOpenEditForm(banner)}
                                            >
                                                수정
                                            </Button>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleOpenDeleteModal(banner)}
                                            >
                                                삭제
                                            </Button>
                                        </td>
                                    </tr>
                                )) : ""}
                                </tbody>
                            </Table>
                        </Tab>
                    })
                }
            </Tabs>
            {/* 배너 목록 테이블 */}


            {/* 등록/수정 모달 */}
            <Modal show={showFormModal} onHide={() => setShowFormModal(false)}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {formData.id === null ? "새 배너 등록" : "배너 수정"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>제목</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>링크 URL</Form.Label>
                            <Form.Control
                                type="text"
                                name="linkUrl"
                                value={formData.linkUrl}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>배너 설명</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>배너 타입</Form.Label>
                            <Form.Select
                                name="bannerType"
                                value={formData.bannerType}
                                onChange={handleChange}
                            >
                                <option value="MAIN">MAIN</option>
                                <option value="LSIDE">SIDE(L)</option>
                                <option value="RSIDE">SIDE(R)</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="isActiveCheckbox">
                            <Form.Check
                                type="checkbox"
                                label="활성화 여부"
                                name="isActive"
                                checked={formData.isActive}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>정렬 순서</Form.Label>
                            <Form.Control
                                type="number"
                                name="displayOrder"
                                value={formData.displayOrder}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>노출 시작일</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                step="60" // 시:분까지만
                                name="startDate"
                                value={formData.startDate}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>노출 종료일</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                step="60"
                                name="endDate"
                                value={formData.endDate}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>이미지 파일</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowFormModal(false)}>
                            닫기
                        </Button>
                        <Button variant="primary" type="submit">
                            {formData.id === null ? "등록" : "수정"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* 삭제 확인 모달 */}
            <Modal show={!!deleteTarget} onHide={() => setDeleteTarget(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>배너 삭제</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>{deleteTarget?.title}</strong> 배너를 삭제하시겠습니까?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteTarget(null)}>
                        취소
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        삭제
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default BannerManagement;