import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./AboutJJmotors.module.css";
import ContactForm from "../../components/contact/ContactForm"
import {Link} from "react-router-dom";
import CoreFeatures from "../../components/core-features/CoreFeatures";

const AboutJJmotors = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.aboutContainer}>
            {/* 헤더 섹션 */}
            <header className={styles.header}>
                <img src="/images/Logo.png" alt="JJ MOTOR Logo" className={styles.logo} />
                <h1>{t('about.title')}</h1>
            </header>

            {/* 소개 섹션 */}
            <section className={styles.intro}>
                <div className={styles.textContent}>
                    <h2>{t('about.introTitle')}</h2>
                    <p>{t('about.introText1')}</p>
                    <p>{t('about.introText2')}</p>
                </div>
                <img src="/images/about.png" alt={t('about.companyImageAlt')} className={styles.companyImage} />
            </section>

            <CoreFeatures></CoreFeatures>

            {/* 미션 및 비전 */}
            <section className={styles.mission}>
                <h2>{t('about.missionTitle')}</h2>
                <div className={styles.missionContent}>
                    <div className={styles.missionBox}>
                        <h3>{t('about.mission')}</h3>
                        <img src="/images/port.jpeg" alt="port" />
                        <p>{t('about.missionText')}</p>
                    </div>
                    <div className={styles.missionBox}>
                        <h3>{t('about.vision')}</h3>
                        <img src="/images/city_view.jpg" alt="city" />
                        <p>{t('about.visionText')}</p>
                    </div>
                </div>
            </section>

            {/* 협력 및 파트너십 */}
            <section className={styles.partnership}>
                <h2>{t('about.partnershipTitle')}</h2>
                <div>
                    <img src="/images/kotra_signature_eng.jpg" alt="KOTRA" />
                    <img src="/images/KITA_eng.jpg" alt="KITA" />
                </div>
                <p>{t('about.partnershipText')}</p>
            </section>

            <div className={styles.moreInfoContainer}>
                <Link className={styles.moreInfo} to="/gallery">{t('about.moreInfoButton')}</Link>
            </div>

            <section>
                <ContactForm />
            </section>
        </div>
    );
};

export default AboutJJmotors;
